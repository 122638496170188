import api from 'o365.modules.data.api.ts';

namespace AzureStorageApi {

    export enum ApiEndpoints {
        Table = '/api/azure/table',
        TableEntity = '/api/azure/table/entity',
        Container = '/api/azure/container',
        Blob = '/api/azure/container/blob',
        //Queue = 'api/azure/queue',
        //Message = 'api/azure/queue/message',
    }

    export enum SystemLocation {
        StaticFileContainer = 'staticFileContainer',
        StaticFileTable = 'staticFileTable',
        Snippets = 'snippets'
    }

    /**
     * @param {string} accountName - storage account name.
     * @param {string} entityName - table/container/queue name to perform operations on. Leave empty for service level client.
     * @param {string} sasToken - token to use for authentication. Optional, managed identity is used by default.
     * @param {number} connectionString - Connection string name to use
     * @param {SystemLocation} systemLocation - predefined system location to use.
     */
    export interface IBaseClientOptions {
        accountName?: string;
        entityName?: string;
        sasToken?: string;
        connectionString?: string;
        systemLocation?: SystemLocation | string;
    }

    /**
     * Azure Storage Client Base.
     * 
     * Allows for CRUD operations in Azure Storage on entity and service levels. 
     */
    export abstract class ClientBase {
        #accountName: string;
        #entityName: string;
        #sasToken: string;
        #connectionString: string;
        #systemLocation: SystemLocation | string;

        /**
         * CTOR.
         * @param {IClientOptions} pOptions - Client builder options.
         */
        constructor({
            accountName,
            entityName,
            sasToken,
            connectionString,
            systemLocation,
        }: IBaseClientOptions) {
            this.#accountName = accountName;
            this.#entityName = entityName;
            this.#sasToken = sasToken;
            this.#connectionString = connectionString;
            this.#systemLocation = systemLocation;
        }

        public appendClientOptions(requestBody: RequestBase) {
            if (this.#accountName !== null) requestBody.accountName = requestBody.accountName ?? this.#accountName;
            if (this.#entityName !== null) requestBody.entityName = requestBody.entityName ?? this.#entityName;
            if (this.#sasToken !== null) requestBody.sasToken = requestBody.sasToken ?? this.#sasToken;
            if (this.#connectionString !== null) requestBody.connectionString = requestBody.connectionString ?? this.#connectionString;
            if (this.#systemLocation !== null) requestBody.systemLocation = requestBody.systemLocation ?? this.#systemLocation;
        }
        

        private async request(
            url: string,
            requestBody: RequestBase,
            headers: Headers = new Headers({
                'Accept': 'application/json',
            }),
            abortSignal: AbortSignal = null): Promise<any> {
            this.appendClientOptions(requestBody);

            await api.request({
                requestInfo: url,
                body: JSON.stringify(requestBody),
                headers: headers,
                method: 'POST',
                abortSignal: abortSignal,
            });
        }

        public async requestJson(url: string, requestBody: RequestBase, abortSignal: AbortSignal = null): Promise<any> {
            this.appendClientOptions(requestBody);

            return await api.requestPost(url, JSON.stringify(requestBody), abortSignal);
        }

        public async requestStream(url: string, requestBody: RequestBase): Promise<ReadableStreamDefaultReader<Uint8Array>> {
            this.appendClientOptions(requestBody);

            return await api.requestStream(url, JSON.stringify(requestBody));
        }

        // -------[ Queues ] ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        // -------[ Messages ] ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        // -------[ Containers ] --------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    }

    // -------[ Models ] ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    /**
     * Defines a base request body.
     */
    export abstract class RequestBase {
        accountName?: string;
        entityName?: string;
        sasToken?: string;
        connectionString?: string;
        systemLocation?: SystemLocation | string;

        /**
         * CTOR.
         * @param {string} entityName - container, table or queue name to perform operations against. Leave null for service level operations.
         */
        constructor(entityName: string = null) {
            this.entityName = entityName;
        }
    }

    // -------[ Queues ] ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    // -------[ Messages ] ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------


    /**
     * Defines available blob operations.
     */
    export enum BlobOperation {
        Get = 'Get',
        GetStaticFile = 'GetStaticFile',
        Put = 'Put',
    }

    /**
     * Defines blob request options.
     */
    export interface IBlobRequestOptions {
        /**
         * Blob operation to execute.
         */
        operation?: BlobOperation,
        /**
         * File name with path.
         */
        fileNameWithPath?: string;
        /**
         * File to upload.
         */
        file?: File;
        /**
         * Path to upload file to.
         */
        filePath?: string;
        /**
         * Metadata to append during Put operation.
         */
        metaData?: Map<string, string>;
    }

    /**
     * Defines a blob request;
     */
    export class BlobRequest extends RequestBase {
        /**
         * Blob operation to execute.
         */
        operation?: BlobOperation;
        /**
         * File name with path.
         */
        fileNameWithPath?: string;

        /**
         * CTOR.
         */
        constructor({ fileNameWithPath = null, operation = null }: IBlobRequestOptions) {
            super();
            this.operation = operation;
            this.fileNameWithPath = fileNameWithPath;
        }
    }

    // -------[ Blob Client ]----------------------------------------------------------------------------------------------------------------------------------------------------------------------
    export class BlobClient extends ClientBase {
        constructor({ accountName = null, entityName = null, sasToken = null, connectionString = null, systemLocation = null }: IBaseClientOptions) {
            super({ accountName: accountName, entityName: entityName, sasToken: sasToken, connectionString: connectionString, systemLocation: systemLocation });
        }

        static #StaticFileContainer(): BlobClient {
            return new BlobClient({ systemLocation: SystemLocation.StaticFileContainer });
        }

        static get StaticFiles(): BlobClient {
            return this.#StaticFileContainer();
        }

        
        public async getBlobJson({ fileNameWithPath }: IBlobRequestOptions): Promise<any> {
            return await this.requestJson(
                ApiEndpoints.Blob,
                new BlobRequest({ fileNameWithPath: fileNameWithPath, operation: BlobOperation.Get })
            );
        }
        

        // -------[ Blobs ] -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        public async getBlob({ fileNameWithPath }: IBlobRequestOptions): Promise<any> {
            const result = await this.requestStream(
                ApiEndpoints.Blob,
                new BlobRequest({ fileNameWithPath: fileNameWithPath, operation: BlobOperation.Get })
            );

            const bytes = await result.read();
            
            try {
                const str = new TextDecoder().decode(bytes.value);
                try {
                    const obj = JSON.parse(str);
                    return obj;
                } catch (ex) {
                    return str;
                }
            } catch (ex) {
                console.log(ex);
            }

            return bytes;
        }

        

        public async getStaticFile({ fileNameWithPath }: IBlobRequestOptions): Promise<any> {
            return await this.requestJson(
                ApiEndpoints.Blob,
                new BlobRequest({ fileNameWithPath: fileNameWithPath, operation: BlobOperation.GetStaticFile })
            );
        }

        public async putBlob({ file, filePath = null, metaData = null }: IBlobRequestOptions, abortSignal: AbortSignal = null): Promise<any> {
            const fileNameWithPath = filePath == null ? file.name : `${filePath}/${file.name}`;

            let putRequest = new BlobRequest({
                fileNameWithPath: fileNameWithPath,
                metaData: metaData,
                operation: BlobOperation.Put,
            });
            this.appendClientOptions(putRequest);
            let formData = new FormData();
            formData.append('file', file);
            formData.append('request', JSON.stringify(putRequest));

            return await api.request({
                requestInfo: ApiEndpoints.Blob,
                method: 'POST',
                headers: new Headers({
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                }),
                body: formData,
                abortSignal: abortSignal,
            });
        }

        // -------[ Containers ] --------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    }

    // -------[ Table Client ]----------------------------------------------------------------------------------------------------------------------------------------------------------------------
    export class TableClient extends ClientBase {
        constructor({ accountName = null, entityName = null, sasToken = null, connectionString = null, systemLocation = null }: IBaseClientOptions) {
            super({ accountName: accountName, entityName: entityName, sasToken: sasToken, connectionString: connectionString, systemLocation: systemLocation });
        }

        static #StaticFilesTable(): TableClient {
            return new TableClient({ systemLocation: SystemLocation.StaticFileTable });
        }

        static get StaticFiles(): TableClient { return this.#StaticFilesTable(); }

        static #Snippets(): TableClient {
            return new TableClient({ systemLocation: SystemLocation.Snippets });
        }

        static get Snippets(): TableClient { return this.#Snippets(); }

        /**
         * Query entities within a table.
         */
        queryTableEntities = async({ filter = null, top = null, select = null, continuationKey = null }: ITableEntityRequestOptions = {}, abortSignal: AbortSignal = null): Promise<object>=> {            
            return await this.requestJson(
                ApiEndpoints.TableEntity,
                new TableEntityRequest({
                    select: select,
                    filter: filter,
                    top: top,
                    continuationKey: continuationKey,
                    operation: TableEntityOperation.Query
                }),
                abortSignal
            );
        }

        /**
         * Insert an entity into a table.
         */
        insertTableEntity = async ({ entity }: ITableEntityRequestOptions, abortSignal: AbortSignal = null): Promise<object> => {
            return await this.requestJson(
                ApiEndpoints.TableEntity,
                new TableEntityRequest({
                    entity: entity,
                    operation: TableEntityOperation.Insert
                }),
                abortSignal
            );
        }

        /**
         * Update an entity within a table.
         * 
         * TableUpdateMode defaults to Merge.
         * Upsert defaults to false.
         */
        updateTableEntity = async({ entity, eTag = null, upsert = false, updateMode = TableEntityUpdateMode.Merge }: ITableEntityRequestOptions, abortSignal: AbortSignal = null): Promise<object>=> {
            return await this.requestJson(
                ApiEndpoints.TableEntity,
                new TableEntityRequest({
                    entity: entity,
                    eTag: eTag,
                    upsert: upsert,
                    updateMode: updateMode,
                    operation: TableEntityOperation.Update
                }),
                abortSignal
            );
        }

        /**
         * Delete an entity from table.
         */
        deleteTableEntity = async ({ entity }: ITableEntityRequestOptions, abortSignal: AbortSignal = null): Promise<object> => {
            return await this.requestJson(
                ApiEndpoints.TableEntity,
                new TableEntityRequest({
                    entity: entity,
                    operation: TableEntityOperation.Delete
                }),
                abortSignal
            );
        }

        // -------[ Tables ]--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        /**
         * Queries existing tables.
         */
        public async queryTables({ filter = null, top = null, continuationKey = null }: ITableRequestOptions = {}, abortSignal: AbortSignal = null): Promise<object> {
            return await this.requestJson(
                ApiEndpoints.Table,
                new TableRequest({
                    filter: filter,
                    top: top,
                    continuationKey: continuationKey,
                    operation: TableOperation.Query
                }),
                abortSignal
            );
        }

        /**
         * Creates a table.
         * 
         * Returns a response with table details if created. If table already exists returns null.
         */
        public async createTable(tableName: string, abortSignal: AbortSignal = null): Promise<object> {
            return await this.requestJson(
                ApiEndpoints.Table,
                new TableRequest({
                    tableName: tableName,
                    operation: TableOperation.Create
                }),
                abortSignal
            );
        }

        /**
         * Deletes a table.
         * 
         * Returns null if table does not exist.
         */
        // public async deleteTable(tableName: string, abortSignal: AbortSignal = null) : Promise<any> {
        //     return await this.requestJson(ApiEndpoints.Table, new TableRequest({tableName: tableName, operation: TableOperation.Delete}), abortSignal);
        // }
    }


    // -------[ Table Entitites ] ---------------------------------------------------------------------------------------------------------------------------------------------------------------------

    /**
     * Defines table entity operations.
     */
    export enum TableEntityOperation {
        Query = 'Query',
        Insert = 'Insert',
        Update = 'Update',
        Delete = 'Delete',
    }

    /**
     * Defines query request options.
     */
    export interface ITableEntityRequestOptions {
        /**
         * Filter expression. https://learn.microsoft.com/en-us/rest/api/storageservices/querying-tables-and-entities#supported-comparison-operators
         */
        filter?: string;
        /**
         * Returns only the top n tables from the set.
         */
        top?: number;
        /**
         * Continutation key for paged requests.
         */
        continuationKey?: string;
        /** 
         * Defines returned fields to return when querying table entities.
         */
        select?: string[];
        /** 
         * Table name to perform operations on. Defaults to Client.entityName parameter. 
         */
        tableName?: string;
        /**
         * Entity to insert, update or delete.
         */
        entity?: ITableEntity;
        /**
         * Insert, update or delete precondition check. If the value does not match table entity's eTag the operation will be aborted.
         */
        eTag?: string;
        /**
         * Specifies if a record should be inserted during update operation if it does not exist. Defaults to false.
         */
        upsert?: boolean;
        /**
         * Specifies whether a method should be merged or replaced during update operation. Defauls to merge.
         */
        updateMode?: TableEntityUpdateMode;
        /**
         * Specifies the operation to execute.
         */
        operation?: TableEntityOperation
    }

    /**
     * Specifies behavior during update operation.
     */
    export enum TableEntityUpdateMode {
        Merge = 'Merge',
        Replace = 'Replace',
    }

    /**
     * Defines required properties for a table entity.
     */
    export interface ITableEntity {
        PartitionKey: string;
        RowKey: string;
        [key: string]: any;
    }

    export class TableEntityRequest extends RequestBase {
        filter?: string;
        top?: number;
        continuationKey?: string;
        select?: string[];
        entity?: ITableEntity;
        eTag?: string;
        upsert?: boolean;
        updateMode?: TableEntityUpdateMode;
        operation?: TableEntityOperation;

        constructor({ filter = null, top = null, continuationKey = null, select = null, tableName = null, entity = null, eTag = null, upsert = false, updateMode = TableEntityUpdateMode.Merge, operation = null }: ITableEntityRequestOptions) {
            super(tableName);
            this.filter = filter;
            this.top = top;
            this.connectionString = continuationKey;
            this.select = select;
            this.entity = entity;
            this.eTag = eTag;
            this.upsert = upsert;
            this.updateMode = updateMode;
            this.operation = operation;
        }
    }

    // -------[ Tables ] ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    /**
     * Defines table operations.
     */
    export enum TableOperation {
        Query = 'Query',
        Create = 'Create',
        //Delete = 'Delete',
    }

    /**
     * Defines query request options.
     * @param {string} filter - filter expression.
     * @param {number} top - Returns only the top n tables from the set.
     * @param {string} continuationKey - continutation key for paged requests.
     * @param {string} tableName -  table name for create or delete operations.
     */
    export interface ITableRequestOptions {
        filter?: string;
        top?: number;
        continuationKey?: string;
        tableName?: string;
        operation?: TableOperation;
    }

    /**
     * Defines a base request body for querying tables or entities.
     * 
     * @param {string[]} select - only used when quering table entities to return.
     */
    export class TableRequest extends RequestBase {
        filter?: string;
        top?: number;
        continuationKey?: string;
        tableName?: string;
        operation?: TableOperation;

        /**
         * CTOR.
         * @param {string} filter - filter expression.
         * @param {number} top - Returns only the top n tables from the set.
         * @param {string} continuationKey - continutation key for paged requests.
         * @param {string} tableName - table name for create or delete operations.
         * @param {TableOperation} operation - specifies the operation to execute.
         */
        constructor({ filter = null, top = null, continuationKey = null, tableName = null, operation = null }: ITableRequestOptions) {
            super();
            this.filter = filter;
            this.top = top;
            this.continuationKey = continuationKey;
            this.tableName = tableName;
            this.operation = operation;
        }
    }
}

export default AzureStorageApi;