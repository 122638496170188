<script setup>
import { nextTick, onMounted, ref, watch } from 'vue';
import { MRegister, MCard, getOrCreateFilter, MBottomSheetRouter, MBottomSheetRoute } from "o365-mobile";
import { OPersonsLookup } from "o365-system-lookups";
import MobileHazardsCard from 'sja.vue.libraries.mob.MobileHazardsCard.vue';
import HazardsSummaryCard from 'sja.vue.libraries.components.HazardsSummaryCard.vue';
import InitialMatrixModal from 'sja.vue.libraries.components.InitialMatrixModal.vue'
import FinalMatrixModal from 'sja.vue.libraries.components.FinalMatrixModal.vue'
import { procDeleteTask, reloadKey, expandedItems } from 'sja.vue.libraries.modules.utilities.js'
import { getOrCreateProcedure } from 'o365-modules';
import { getDataObjectById, getOrCreateDataObject } from 'o365-dataobject';
import OModal from 'o365.vue.components.Modal.vue';

const props = defineProps({
    itemID: Number,
    sjaID: Number,
    readOnly: Boolean,
    participants: Boolean,
    showTasks: Boolean
});

const dsSJA = getOrCreateDataObject({
    id: `dsSJA_${props.sjaID}`,
    uniqueTable: 'atbv_SJA_SJA',
    maxRecords: -1,
    selectFirstRowOnLoad: true,
    viewName: 'aviw_SJA_SJA',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'Step_ID', },
        { name: 'Name', },
        { name: 'Description', },
        { name: 'Planned', },
        { name: 'Completed', },
        { name: 'OrgUnit_ID', },
        { name: 'OrgUnitIdPath', },
        { name: 'OrgUnit', },
        { name: 'Responsible_ID', },
        { name: 'Responsible', },
        { name: 'IsTemplate', },
        { name: 'Checklist_ID', },
        { name: 'CheckList_Editable', },
        { name: 'Checklist', }
    ],
    whereClause: "ID = " + props.sjaID
});

const dsTasks = getOrCreateDataObject({
    id: `dsTasks_${props.sjaID}`,
    uniqueTable: 'atbv_SJA_SJATasks',
    maxRecords: -1,
    viewName: 'aviw_SJA_SJATasks',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'SJA_ID', },
        {
            name: 'TaskNo',
            sortOrder: 1,
            sortDirection: "asc",
        },
        { name: 'Description', },
        { name: 'Comment', }
    ],
    whereClause: `SJA_ID = ${props.sjaID}`,
    masterDataObject_ID: `dsSJA_${props.sjaID}`,
    masterDetailDefinition: [
        {
            masterField: "ID",
            detailField: "SJA_ID",
            operator: "equals"
        }
    ],
});

let dsHazards = getOrCreateDataObject({
    id: `dsTasksHazards_${props.sjaID}`,
    uniqueTable: 'atbv_SJA_SJATasksHazards',
    maxRecords: -1,
    viewName: 'aviw_SJA_SJATasksHazards',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'Name', },
        { name: 'Color', },
        { name: 'FinalColor', },
        { name: 'Matrix_ID', },
        { name: 'SJATask_ID', },
        { name: 'FinalMatrix_ID', },
        { name: 'ProbabilityNameAndDesc', },
        { name: 'ConsequenceNameAndDesc', },
        { name: 'FinalProbabilityNameAndDesc', },
        { name: 'FinalConsequenceNameAndDesc', },
        { name: 'ProbabilityValue', },
        { name: 'ConsequenceValue', },
        { name: 'SJA_ID', },
        { name: 'FinalProbabilityValue', },
        { name: 'FinalConsequenceValue', },
        { name: 'ExistingActions', },
        { name: 'Consequences', },
        { name: 'Actions', }
    ],
    whereClause: `SJA_ID = ${props.sjaID}`,
});

let dsActions = getOrCreateDataObject({
    id: `dsTasksHazardsActions_${props.sjaID}`,
    uniqueTable: 'atbv_SJA_SJATasksHazardsActions',
    maxRecords: -1,
    viewName: 'aviw_SJA_SJATasksHazardsActions',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'Name', },
        { name: 'Comment', },
        { name: 'IsVerified', },
        { name: 'SJATasksHazard_ID', },
        { name: 'SJA_ID', }
    ],
    whereClause: `SJA_ID = ${props.sjaID}`,
});

let dsResponsible = getOrCreateDataObject({
    id: `dsTasksHazardsActionsResp_${props.sjaID}`,
    uniqueTable: 'atbv_SJA_SJATasksHazardsActionsResponsibles',
    maxRecords: -1,
    viewName: 'aviw_SJA_SJATasksHazardsActionsResponsibles',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'SJATasksHazardsAction_ID', },
        { name: 'Responsible_ID', },
        { name: 'StepAction_ID', },
        { name: 'Completed', },
        { name: 'SJA_ID', },
        { name: 'Name', }
    ],
    whereClause: `SJA_ID = ${props.sjaID}`,
});

const dsConsequencesLkp = getOrCreateDataObject({
    id: `dsConsequencesLkp`,
    maxRecords: -1,
    viewName: 'aviw_SJA_Consequences',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'ID', },
        { name: 'Name', },
        { name: 'Value', },
        { name: 'Description', },
        { name: 'ValueAndName', },
        { name: 'NameAndDescription', },
        { name: 'ValueAndNameAndDescription', }
    ],
});

const dsProbabilitiesLkp = getOrCreateDataObject({
    id: `dsProbabilitiesLkp`,
    maxRecords: -1,
    viewName: 'aviw_SJA_Probabilities',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'ID', },
        { name: 'Name', },
        { name: 'Value', },
        { name: 'Description', },
        { name: 'ValueAndName', },
        { name: 'NameAndDescription', },
        { name: 'ValueAndNameAndDescription', }
    ],
});

const dsHazardsModalInitial = getOrCreateDataObject({
    id: `dsTasksHazardsModalInitial`,
    uniqueTable: 'atbv_SJA_SJATasksHazards',
    selectFirstRowOnLoad: true,
    maxRecords: -1,
    viewName: 'aviw_SJA_SJATasksHazards',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'Name', },
        { name: 'Color', },
        { name: 'FinalColor', },
        { name: 'Matrix_ID', },
        { name: 'SJATask_ID', },
        { name: 'SJA_ID', },
        { name: 'FinalMatrix_ID', },
        { name: 'ProbabilityNameAndDesc', },
        { name: 'ConsequenceNameAndDesc', },
        { name: 'FinalProbabilityNameAndDesc', },
        { name: 'FinalConsequenceNameAndDesc', },
        { name: 'ProbabilityValue', },
        { name: 'ConsequenceValue', },
        { name: 'FinalProbabilityValue', },
        { name: 'FinalConsequenceValue', }
    ],
});

const dsHazardsModalFinal = getOrCreateDataObject({
    id: `dsTasksHazardsModalFinal`,
    uniqueTable: 'atbv_SJA_SJATasksHazards',
    selectFirstRowOnLoad: true,
    maxRecords: -1,
    viewName: 'aviw_SJA_SJATasksHazards',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'Name', },
        { name: 'Color', },
        { name: 'FinalColor', },
        { name: 'Matrix_ID', },
        { name: 'SJATask_ID', },
        { name: 'SJA_ID', },
        { name: 'FinalMatrix_ID', },
        { name: 'ProbabilityNameAndDesc', },
        { name: 'ConsequenceNameAndDesc', },
        { name: 'FinalProbabilityNameAndDesc', },
        { name: 'FinalConsequenceNameAndDesc', },
        { name: 'ProbabilityValue', },
        { name: 'ConsequenceValue', },
        { name: 'FinalProbabilityValue', },
        { name: 'FinalConsequenceValue', },
        { name: 'ProbabilityValueID', },
        { name: 'ConsequenceValueID', },
        { name: 'FinalProbabilityValueID', },
        { name: 'FinalConsequenceValueID', }
    ],
});

const dsTasksLkp = getOrCreateDataObject({
    id: `dsTasksLkp`,
    maxRecords: -1,
    viewName: 'aviw_SJA_SJATasks',
    allowUpdate: false,
    allowInsert: false,
    allowDelete: false,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        {
            name: 'ID',
            sortOrder: 1,
            sortDirection: "asc",
        },
        { name: 'SJA_ID', },
        { name: 'SJAName', },
        { name: 'TaskNo', },
        { name: 'Description', },
        { name: 'Comment', }
    ],
});

const filter = getOrCreateFilter({
    id: "Tasks",
    // registerID: Url.getParam('ID'),
    dataObject: dsTasks,
    searchFields: ["Description", "TaskNo"],
    requiredFields: [],
    fields: [
        // { type: "Checkbox", field: "c_includeSigned", name: $t("Have signed"), checkedClause: "Signed IS NOT NULL" },
        // { type: "Checkbox", field: "c_includeNotSigned", name: $t("Have not signed"), checkedClause: "Signed IS NULL" },
        // { type: "Checkbox", field: "c_onlyMyself", name: $t('Show only myself'), checkedClause: `Person_ID = ${userSession.personId}` },
        // { type: "Distinct", field: "Name", name: $t("Names") }
    ],
    orders: [
        { name: $t("By Task Number (Ascending)"), order: [{ "TaskNo": "Asc" }] },
        { name: $t("By Task Number (Descending)"), order: [{ "TaskNo": "Desc" }] },
        { name: $t("Alphabetically (A-Z)"), order: [{ "Description": "Asc" }] },
        { name: $t("Alphabetically (Z-A)"), order: [{ "Description": "Desc" }] }
    ]
});

onMounted(() => {
    document.querySelectorAll('.afm-container').forEach(element => {
        element.style.position = 'static'
    });
    dsSJA.load();
})

const procRenumberItems = getOrCreateProcedure({ id: "procRenumberItems", procedureName: "astp_SJA_TaskItemsRenumbering" });
const procCopyTaskRow = getOrCreateProcedure({ id: "procCopyTaskRow", procedureName: "astp_SJA_CopyTaskRow" });
const procCreateHazardRow = getOrCreateProcedure({ id: "procCreateHazardRow", procedureName: "astp_SJA_CreateHazardRow" });

let prevTaskNo = null;
let disableNew = ref(false);
const modalRef = ref(null);
const showTasks = ref(props.showTasks);

async function renumberItems(currTaskNo) {
    if (currTaskNo !== prevTaskNo && prevTaskNo !== null) {
        await procRenumberItems.execute({
            SJA_ID: props.sjaID,
            Curr_No: currTaskNo,
            Prev_No: prevTaskNo
        }, function (pErr, pData) {
            if (pErr === null) {
            }
        });
    }
    await dsTasks.load();
}

dsTasks.on("BeforeSave", async function (options, row) {
    prevTaskNo = row.TaskNo
});

dsTasks.on("AfterSave", async function (options, row) {
    if (options.operation === "create") {
        await newRow(row);
    } else {
        await renumberItems(row.TaskNo);
    }
    disableNew.value = false;
});

async function deleteTask(row) {
    if (confirm($t("Are you sure you want to delete:") + "\n" + row.Description)) {
        row.delete();
    }
}

async function deleteHazard(row) {
    if (confirm($t("Are you sure you want to delete:") + "\n" + row.Name)) {
        row.delete();
    }
}

dsTasks.on("DataLoaded", async function (options, row) {
    await dsHazards.load();
    await dsActions.load();
    await dsResponsible.load();

    // if (document.querySelector(`[data-o365-container="N"]`)) {
        // document.querySelector(`[data-o365-container="N"]`).classList.add("sticky-bottom", "bg-white", "border-top", "z-1");
    // }
});

async function newRow(row) {
    let dsTasksHazards = null;
    let dsTasksHazardsActions = null;
    let dsTasksHazardsActionsResp = null;
    try {
        await procCreateHazardRow.execute({ ID: row.ID });
        dsTasksHazards = getDataObjectById(`dsTasksHazards_${row.SJA_ID}`);
        dsTasksHazardsActions = getDataObjectById(`dsTasksHazardsActions_${row.SJA_ID}`);
        dsTasksHazardsActionsResp = getDataObjectById(`dsTasksHazardsActionsResp_${row.SJA_ID}`);
        await dsTasksHazards.load();
        await dsTasksHazardsActions.load();
        await dsTasksHazardsActionsResp.load();
    } catch (error) {
        console.error("Error loading data:", error);
    }
}

async function newTask() {
    await dsTasks.createNew({ SJA_ID: props.sjaID, TaskNo: dsTasks.data.length + 1 }, true, false);
    document.querySelector('.app-container').scrollTo({
        top: 0,
        behavior: 'smooth'
    });
    modalRef.value.hide();
};

async function CopyTask(row) {
    let newID;
    try {
        newID = await procCopyTaskRow.execute({ SjaID: props.sjaID, TaskID: row.ID, TaskNo: dsTasks.data.length + 1 });
        console.log(newID.Table[0].NewID, "OK");
        await dsTasks.load();
        const element = document.getElementById('taskRow' + newID.Table[0].NewID + '2');
        const container = document.querySelector('.app-container');
        const elementTop = element.getBoundingClientRect().top + container.scrollTop;
        const offset = 300;
        container.scrollTo({ top: elementTop - offset, behavior: 'smooth' })
    } catch (error) {
        console.error("Error loading data:", error);
    }

    modalRef.value.hide();
}

function toggleExpand(rowID) {
    expandedItems.value[rowID] = !expandedItems.value[rowID];
}

function openTab() {
    dsTasks.load();
}

defineExpose({
    openTab
})

async function navigateToTask(taskID, router) {
    dsHazards.recordSource.whereClause = `SJATask_ID = ${taskID}`;
    await dsHazards.load();
    router.assign(`/task-details?ID=${taskID}`);
}

</script>

<template>
    <OModal style="z-index: 9000;" ref="modalRef">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add new Task</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <ODataLookup @beforeopen="dsTasksLkp.load()" v-if="!props.readOnly" contextField="IdPath"
                        :data-object="dsTasksLkp" :bind="sel => { CopyTask(sel) }">
                        <OColumn field="ID" width="73" sortable></OColumn>
                        <OColumn :headerName="$t('SJA')" field="SJAName" width="300"></OColumn>
                        <OColumn :headerName="$t('Task')" field="Description" width="205"></OColumn>
                        <template #target="scope" @click="scope.open">
                            <a type="button" :ref="scope.target" class="form-control d-flex justify-content-center" :title="$t('Copy an existing task')"
                                aria-expanded="false">
                                {{ $t('Copy a task') }}
                            </a>
                        </template>
                    </ODataLookup>
                    <button class="mt-2 form-control" @click="newTask()">
                        {{ $t('Or add new') }}
                    </button>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('Cancel') }}</button>
                </div>
            </div>
        </div>
    </OModal>

    <InitialMatrixModal style="z-index: 7000;"></InitialMatrixModal>
    <FinalMatrixModal style="z-index: 7000;"></FinalMatrixModal>

    <ORowContainer appContainer>
        <div class="p-3">
            <a class="flex-grow-1 d-flex justify-content-center align-items-center gap-2 fw-medium text-primary" @click="showTasks = true">
                <span>{{ $t('Click to open all Tasks') }}</span>
                <i class="bi bi-caret-down-fill"></i>
            </a>
        </div>
    </ORowContainer>

    <MBottomSheetRouter v-model="showTasks">
        <MBottomSheetRoute path="/" :header="$t('Menu')" #="{ router }">
            <div>
                <MRegister :dataObject="dsTasks" :filter="filter" :title="$t('Tasks')" :whereClause="`SJA_ID = ${props.sjaID}`"
                    hideFolderView>
                    <template #card="{ row: taskRow }">
                        <MCard>
                            <div class="d-flex" @click="navigateToTask(taskRow.ID, router)">
                                <a class="align-self-center w-100 text-truncate" :text="`No. ${taskRow.TaskNo} ${taskRow.Description ? taskRow.Description : ''}`"/>
                                <span class="ms-auto" @click="deleteTask(taskRow)"><i class="text-black bi bi-x-lg"></i></span>
                            </div>
                        </MCard>
                    </template>
                </MRegister>
                <div v-if="!props.readOnly" class="dropup">
                    <a @click="modalRef.show" class="d-flex justify-content-center align-items-center text-white afm-floating-button">
                        <i class="bi bi-plus-lg" style="font-size: 2.25em;" />
                    </a>
                </div>
            </div>
        </MBottomSheetRoute>
        <MBottomSheetRoute v-for="taskRow in dsTasks.data" :path="`/task-details?ID=${taskRow.ID}`" :header="$t('Task Details')" #="{ router }">
            <div>
                <div class="font-1 fw-medium text-primary mb-2 d-flex align-items-center">
                    {{ $t('Task No') }}. {{ taskRow.TaskNo }}
                </div>

                <div>
                    <div class="mb-3">
                        <span>{{ $t('Description') }}:</span>
                        <OContentEditable class="w-100 h-100 p-1 text-break" :isEditable="!props.readOnly"
                            v-model="taskRow.Description" :class="!props.readOnly ? 'form-control' : ''"
                            @blur="dsTasks.save()" />
                    </div>
                    <div>
                        <div v-if="!taskRow.isNewRecord" style="max-width:1500px">
                            <div>
                                <span class="mx-auto">{{ $t('All Hazards related to this Task') }}:</span>
                                <MCard v-for="hazard in dsHazards.data" class="mb-1">
                                    <div class="d-flex">
                                        <a class="align-self-center w-100 text-truncate" :text="`${hazard.Name}`" @click="router.assign(`/hazard-details?ID=${hazard.ID}`)" />
                                        <span class="ms-auto" @click="deleteHazard(hazard)"><i class="text-black bi bi-x-lg"></i></span>
                                    </div>
                                </MCard>
                            </div>

                            <div class="d-flex">
                                <button :title="$t('Add a new hazard to task.')"
                                    class="btn btn-sm btn-primary mt-1 px-2 p-1 mx-auto" ref="newHazardRow"
                                    type="button" @click="newRow(taskRow)"
                                    v-if="!props.readOnly && !taskRow.isNewRecord">
                                    <i :title="$t('Add a new hazard.')" class="text-white bi bi-plus-lg"></i>
                                    <span class="text-white">{{ $t('New Hazard') }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MBottomSheetRoute>
        <MBottomSheetRoute v-for="hazard in dsHazards.data" :path="`/hazard-details?ID=${hazard.ID}`" :header="$t('Hazard Details')">
            <div>
                <MobileHazardsCard :hazardID="hazard.ID" :sjaID="props.sjaID" :itemID="props.itemID"
                    :readOnly="props.readOnly" :participants="props.participants" />
            </div>
        </MBottomSheetRoute>
    </MBottomSheetRouter>
</template>

<style scoped>
.inner-table-editable {
    border: 1.5px solid var(--o365-grid-border) !important;
    border-radius: var(--bs-border-radius) !important;
}

.inner-table-editable:focus,
.inner-table-editable:hover {
    outline: 0;
    border: 1.5px solid rgba(70, 130, 180, .25) !important;
    box-shadow: 0px 0px 0px 2px rgba(70, 130, 180, .25) !important;
}

.dropdown-item {
    border: 1.5px solid white !important;
    border-radius: var(--bs-border-radius) !important;
}

.dropdown-item:hover {
    border: 1.5px solid rgba(70, 130, 180, .25) !important;
    box-shadow: 0px 0px 0px 2px rgba(70, 130, 180, .25) !important;
}

.page-container {
    display: flex;
    flex-direction: column;
    min-height: 90vh;
}

div[data-o365-container="N"] {
    position: sticky;
    bottom: 0;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.afm-floating-button {
    position: fixed;
    right: 1.5rem;
    bottom: 1.5rem;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 999px;
    text-decoration: none;
    box-shadow: 4px 8px 16px rgb(0%, 0%, 0%, 10%);
    background-color: #3575B3;
}
</style>