import { getOrCreateDataObject } from "o365-dataobject";
import { getOrCreateProcedure } from "o365-modules";

export default class TagRecognizer {
    readonly sessionId: number
    readonly documentId: number
    readonly pageWidth: number
    readonly pageHeight: number
    patterns: string
    grid: OCRGrid
    constructor(pText: string, pPatterns: string, pDocumentId: number, pPageWidth: number, pPageHeight: number) {
        this.patterns = pPatterns;
        this.pageHeight = pPageHeight;
        this.pageWidth = pPageWidth;
        this.sessionId = getSessionId();
        this.documentId = pDocumentId;

        this.grid = new OCRGrid(pPageWidth, pPageHeight);
    }

    private _getObjectMatchesDataObject() {
        const dataObject = getOrCreateDataObject({
            id: 'o_dsObjectMatches',
            viewName: 'atbv_Arena_DocumentsObjectsMatches',
            fields: [
                { name: 'Tag' },
                { name: 'Object_ID' },
                { name: 'Session_ID' }
            ],
        });
        dataObject.recordSource.whereClause = `[Session_ID] = ${this.sessionId}`;

        return dataObject;
    }

    private _getObjectConnectProc() {
        const proc = getOrCreateProcedure<{ Session_ID: number, Document_ID: number }>({
            id: 'procConnectObjectTagMatches',
            procedureName: 'astp_Arena_ConnectObjectTagMatches'
        });

        return proc;
    }
}

class OCRGrid{
    readonly cellHeight: number
    readonly cellWidth: number
    cellAmount: number = 50
    constructor(pPageWidth: number, pPageHeight: number){
        this.cellHeight = pPageWidth / this.cellAmount;
        this.cellWidth = pPageHeight / this.cellAmount;
    }
}

function getSessionId() {
    const max = 2147483647;
    const UID = Math.floor(Math.random() * (max + 1));
    return UID;
}

export { TagRecognizer }; 