<template>
    <ODialog v-model:show="show" name="new-docreg-dialog" :title="$t('New Document Register Folder')" size="lg" ref="dialog">
        <div class="o365-dialog-body d-flex flex-column">
            <div class="row gy-2">
                <div class="col-6">
                    <label>{{ $t("Name") }} *</label>
                    <input class="form-control form-control-sm" v-focus:100 v-model="newDocRegFolder.name" />
                </div>
                <div class="col-6">
                    <label>{{ $t("Org Unit (Used as context for Document Register Folder)") }} *</label>
                    <OOrgUnitsLookup :bind="(sel) => { newDocRegFolder.orgUnit = sel.OrgUnit; newDocRegFolder.orgUnitId = sel.ID; }">
                        <template #orgunit>
                            <input class="form-control form-control-sm" v-model="newDocRegFolder.orgUnit">
                        </template>
                    </OOrgUnitsLookup>
                </div>
            </div>
            <div>
                <label class="ms-2">{{$t("Document Register Filter")}}</label>
                <OActiveFilters :filterObject="filterObject" hideSavedFilters responsiveLayout hideHeader />
                <div class="hstack px-3">
                    <span>{{ filterObject.filterString }}</span>
                </div>
            </div>
        </div>
        <div class="o365-dialog-footer">
            <button class="btn btn-sm btn-primary" @click="create()">
                <span>{{ $t("Create") }}</span>
            </button>
        </div>
    </ODialog>
</template> 

<script setup>
import { ref, defineProps, defineEmits, defineExpose, watch } from 'vue';
import { getOrCreateProcedure } from 'o365-modules';
import { ODialog } from 'o365-ui-components';
import { OOrgUnitsLookup } from 'o365-system-lookups';
import { OActiveFilters } from 'o365-filter-components';
import { getOrCreateDataObject } from 'o365-dataobject';

const emit = defineEmits(['created'])

const props = defineProps({
    folderDataObject: Object,
    filterDataObject: Object
})

const fieldsToUse = ["OrgUnit", "Discipline", "Status", "ReleaseCode", "DocumentType"];
const show = ref(false);
const creating = ref(false);
const dialog = ref();
const newDocRegFolder = ref({
    name: null,
    orgUnitId: null,
    orgUnit: null
})

const procCreateNewFolder = getOrCreateProcedure({ id: "procCreateNewFolder", procedureName: "astp_Shared_FoldersCreateFolder" });

const dsFilterObject = getOrCreateDataObject({
    id: 'dsFilterObject',
    viewName: props.filterDataObject.viewName,
    maxRecords: 0,
    definitionProc: props.filterDataObject.recordSource.definitionProc,
    whereClause: props.filterDataObject.recordSource.whereClause,
    fields: props.filterDataObject.getFields().filter(x => fieldsToUse.includes(x.name)).map((field) => ({ ...field.item, tppe: field.type}))
});

const filterObject = dsFilterObject.filterObject;
filterObject.setColumnDistinctOptions("Status", { targetColumn:"Status_ID" });
filterObject.setColumnDistinctOptions("Discipline", { targetColumn:"Discipline_ID" });
filterObject.setColumnDistinctOptions("OrgUnit", { targetColumn:"OrgUnit_ID" });
filterObject.setColumnDistinctOptions("ReleaseCode", { targetColumn:"ReleaseCode_ID" });
filterObject.setColumnDistinctOptions("DocumentType", { targetColumn:"DocumentType_ID" });

fieldsToUse.forEach(item => {
    var filterItem = filterObject.getItem(item);
    filterItem.operators.splice(0, filterItem.operators.length, 'equals');
    filterItem.defaultOperator = 'equals'
});

watch(props.filterDataObject.recordSource.whereClause, () => {
    dsFilterObject.recordSource.whereClause = props.filterDataObject.recordSource.whereClause
}, { immediate: true })

async function create(){
    creating.value = true;
    const data = await procCreateNewFolder.value.execute({
        Name: newDocRegFolder.value.name,
        OrgUnit_ID: newDocRegFolder.value.orgUnitId,
        ParentFolder_ID: props.folderDataObject.current.ID,
        IsDocumentRegister: true,
        DocumentFilter: filterObject.filterString,
        DocumentFObject: JSON.stringify(filterObject.filterObject)
    });
    creating.value = false;
    emit('created', data.Table[0].ID);
    show.value = false;

    newDocRegFolder.value.name = null;
    newDocRegFolder.value.orgUnit = null;
    newDocRegFolder.value.orgUnitId = null;
}

function showDialog(){
    show.value = true;
}

defineExpose({ showDialog })
</script>