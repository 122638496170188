import type { RecordSourceOptions } from 'o365-dataobject';
import type { WorkerInitMessage, NodeDataConfigurationOptions, Node, SortField, Aggregate } from './types.ts';
import type { WorkerMessageChannel as WorkerMessageChannelClass } from './worker.WorkerChannel.ts';

// ----------------------------------------------------
// This is a module worker script. Normal imports will not work here since import maps are unreachable.
// Any imported script must have its import path provided in init.
// ----------------------------------------------------

const ImportMaps = new Map<string, string>();

const vConsole = console;

/**
 * Helper function for importing registered scripts
 * @param pName - Script name to import
 */
function importScript(pName: './worker.WorkerChannel.ts'): Promise<typeof import('./worker.WorkerChannel.ts')>
function importScript(pName: './DataObject.utils.ts'): Promise<typeof import('./DataObject.utils.ts')>
function importScript(pName: string) {
    if (!ImportMaps.has(pName)) {
        throw new Error(`${pName} is not provided to the worker import maps`);
    }
    return import(ImportMaps.get(pName)!) as any;
}

onmessage = (pEvent: MessageEvent<WorkerInitMessage>) => {
    if (pEvent.data.type !== 'init') { return; }
    for (const key in pEvent.data.importMaps) {
        ImportMaps.set(key, pEvent.data.importMaps[key])
    }

    init(pEvent.data.workerId, pEvent.ports[0]);
};

let workerImportPromise: undefined | ReturnType<typeof _getWorkerImportModules> = undefined;

function getWorkerImportModules() {
    if (workerImportPromise) {
        return workerImportPromise;
    }
    workerImportPromise = _getWorkerImportModules();
    return workerImportPromise;
}

async function _getWorkerImportModules() {
    const { WorkerMessageChannel } = await importScript('./worker.WorkerChannel.ts');
    const { sortData } = await importScript('./DataObject.utils.ts');

    class NodeDataWorker {
        channel: WorkerMessageChannelClass;
        type: 'serverSide' | 'clientSide' = 'clientSide';

        configurations: NodeDataConfigurationOptions[] = [];
        uniqueKeyField: string = '';
        structureFields: string[] = [];
        sortFields: Record<string, SortField> = {};
        aggregates: Record<string, Aggregate> = {};

        expandedNodes?: Record<string, boolean>;

        /**
         * Map of loaded nodes. The keys here are from node.$uid.
         * These keys do not come from the server but instead are generated on the client side.
         */
        itemsMap = new Map<string, Node>();
        hierarchyPartialsMap = new Map<string | number, Node>();

        /**
         * Field mappings for withProperties view.
         * This is used to get additional property meta values in the summary items
         */
        propertyFields!: {
            Name?: string;
            Value?: string;
            IntValue?: string;
        }

        constructor(pOptions: {
            id: string,
            port: MessagePort
        }) {
            this.channel = new WorkerMessageChannel({
                id: pOptions.id,
                functions: {
                    'syncOptions': (pOptions) => {
                        this.configurations = pOptions?.configurations ?? [];
                        this.structureFields = pOptions?.structureFields ?? [];
                        this.uniqueKeyField = pOptions?.uniqueKeyField ?? '';
                        this.sortFields = pOptions?.sortFields ?? {};
                        this.expandedNodes = pOptions?.expandedNodes;
                        this.aggregates = pOptions?.aggregates ?? {};
                        this.propertyFields = pOptions?.propertyFields ?? {};

                        return Promise.resolve();
                    },
                    'retrieve': (pOptions) => {
                        if (pOptions?.type == 'clientSide') {
                            return this.clientSideRetrieve(pOptions.options);
                        } else if (pOptions?.type == 'serverSide') {
                            return this.serverSideRetrieve(pOptions.options, pOptions.uidPath)
                        } else {
                            throw new Error('Unkown type for NodeData retreive encountered')
                        }
                    },
                    'destroy': () => {
                        this.destory();
                        return Promise.resolve();
                    }
                },
                port: pOptions.port
            });
        }

        destory() {
            //
        }

        /**
         * Server side group by implementation. This mode creates partial structures that are loaded in as you keep expanding.
         * Custom aggregates and calculated fields will not work as expected here since the data will be undefined until expanded.
         * @param pOptions - Record source options for loading the strucutre
         * @param pUidPath - Path from rooot to current scope. This is used to create the where clause for loading details.
         */
        async serverSideRetrieve(pOptions: RecordSourceOptions, pUidPath: string[]) {
            if (!pUidPath.length) { this.itemsMap.clear(); }
            const parentNodes = [];
            for (const uid of pUidPath) {
                const node = this.itemsMap.get(uid);
                if (node == null) { throw new Error('Something went wrong. NodeData worker recieved a node uid that does not exists') };
                parentNodes.push(node)
            }
            const parentNode = parentNodes.at(-1);

            const bindingClause = this.getBindingClause(parentNodes);;

            if (bindingClause) {
                pOptions.whereClause = pOptions.whereClause
                    ? `(${pOptions.whereClause}) AND ${bindingClause}`
                    : bindingClause;
            }

            const configurationInfo = this.resolveDetailsConfigurationIndex(parentNode);
            const configuration = this.configurations[configurationInfo.index];

            pOptions.fields = this.getFieldsForServerSideLoad(configurationInfo.isLast ? 'normal' : 'group', configuration, parentNodes);

            this.applySortFields(pOptions.fields);
            const data = await this.channel.execute('retrieve', { options: pOptions, type: 'normal' });

            if (!pOptions.filterString) {
                this.storePreviousPartialsForHierarchy(data, configuration);
            }

            const result: Node[] = [];
            let flatResuult: Node[] = [];
            let count = 0;
            let maxDepth = configurationInfo.index;
            if (configurationInfo.isLast || configuration.type == 'hierarchy') {
                const configurationIndex = configurationInfo.isLast
                    ? configurationInfo.index + 1
                    : configurationInfo.index;

                const nodes = this.transformToNodeData(data, configurationIndex, parentNode?.$path);

                for (const node of nodes) {
                    if (parentNode) {
                        node.$parentUid = parentNode.$uid;
                        parentNode.$children.push(node);
                    }
                    result.push(node);
                }
                const traverseResult = await this.traverseAssignNodes(nodes, (parentNode?.$depth != null) ? parentNode.$depth + 1 : undefined, true, false, !!pOptions.filterString);

                maxDepth = traverseResult.maxDepth;
                flatResuult = traverseResult.flatNodes;
                count = traverseResult.count;
            } else if (configuration.type == 'groupBy') {
                for (const item of data) {
                    const groupKey = this.getGroupKey(item, configuration);
                    const parentPath = parentNode ? parentNode.$path : [];
                    const node: Node = {
                        $uid: crypto.randomUUID(),
                        $type: 'summary',
                        $children: [],
                        $path: [...parentPath, groupKey],
                        $item: item,
                        $configurationId: configurationInfo.index,
                        $depth: parentPath.length,
                        $count: item.__count__
                    };
                    count += item.__count__;

                    if (parentNode) {
                        node.$parentUid = parentNode.$uid;
                        parentNode.$children.push(node);
                    }

                    result.push(node);
                }
                const traverseResult = await this.traverseAssignNodes(result, (parentNode?.$depth != null) ? parentNode.$depth + 1 : undefined, false, true, configuration.expandByDefault);
                maxDepth = traverseResult.maxDepth;
                flatResuult = traverseResult.flatNodes;
            }

            return { nodes: result, flatNodes: flatResuult, maxDepth: maxDepth, count };
        }

        /**
         * Client side group by and hierarchy implementation. All of the necessary data is retrieved from the database
         * in a flat array structure. The structure creation then is done on the worker thread and returned to the ui.
         * @param pOptions - Record source options for loading the sturcutre
         */
        async clientSideRetrieve(pOptions: RecordSourceOptions) {
            this.itemsMap.clear();
            pOptions.fields = [];
            const properties = new Set<string>();
            for (const field of this.structureFields) {
                if (field.startsWith('Property.')) {
                    properties.add(field.split('Property.')[1]);
                } else {
                    pOptions.fields.push({ name: field });
                }
            }

            this.applySortFields(pOptions.fields, true);
            const loadingPromises = [];
            const normalLoadPromise = this.channel.execute('retrieve', { options: pOptions, type: 'normal' });
            loadingPromises.push(normalLoadPromise);

            normalLoadPromise.then((data) => {
                const hierarchyConfiguration = this.configurations.find((configuration) => configuration.type == 'hierarchy');
                if (hierarchyConfiguration && !pOptions.filterString) {
                    this.storePreviousPartialsForHierarchy(data, hierarchyConfiguration);
                }
            });


            if (properties.size) {
                const propertiesInList = Array.from(properties).map((property) => `'${property}'`).join(', ')
                const whereClause = pOptions.whereClause
                    ? pOptions.whereClause + ` AND [PropertyName] IN (${propertiesInList})`
                    : `[PropertyName] IN (${propertiesInList})`;

                const fields = new Set([this.uniqueKeyField]);

                for (const key in this.propertyFields) {
                    const fieldName: string = (this.propertyFields as any)[key];
                    fields.add(fieldName);
                }

                const propertiesLoadingPromise = this.channel.execute('retrieve', {
                    options: {
                        ...pOptions,
                        fields: Array.from(fields).map((field) => ({ name: field })),
                        whereClause: whereClause
                    }, type: 'withProperties'
                }).then(async (propertiesData) => {
                    const data = await normalLoadPromise;
                    const itemLookup = new Map<string | number, any>();
                    for (const item of data) {
                        const id = item[this.uniqueKeyField];
                        if (id != null) {
                            itemLookup.set(id, item);
                        }
                    }

                    for (const propertyItem of propertiesData) {
                        const id = propertyItem[this.uniqueKeyField];
                        let item = itemLookup.get(id);

                        if (item) {
                            const propertyName = propertyItem[this.propertyFields.Name!];
                            const propertyKey = 'Property.' + propertyName;
                            item[propertyKey] = propertyItem[this.propertyFields.Value!];
                            if (item.$properties == null) { item.$properties = {}; }
                            item.$properties[propertyName] = {};
                            for (const key in this.propertyFields) {
                                const fieldName: string = (this.propertyFields as any)[key];
                                item.$properties[propertyName][key] = propertyItem[fieldName];
                            }
                        }
                    }
                })
                loadingPromises.push(propertiesLoadingPromise);
            }

            await Promise.all(loadingPromises);

            const data = await normalLoadPromise;

            const result = this.transformToNodeData(data, 0);

            const { flatNodes, maxDepth, count } = await this.traverseAssignNodes(result, undefined, true, false, !!pOptions.filterString);

            return { nodes: result, flatNodes: flatNodes, maxDepth: maxDepth, count };
        }

        /**
         * Traverse a client side node structure and assign parents 
         * @param pNodes - Nodes in a hierarchy structure form
         */
        async traverseAssignNodes(pNodes: Node[], pStartingDepth?: number, pSetCount = true, pSkipClientSideAggregates = false, pExpandByDefault = false) {
            let maxDepth = 0;
            let count = 0;
            const flatNodes: Node[] = [];
            const hasAggregates = Object.keys(this.aggregates).length > 0;

            const bulkAggregates: Record<number, Node[]> = {};

            const traverse = (pNode: Node, pDepth = 0, pCount = 0) => {
                if (this.itemsMap.has(pNode.$uid)) {
                    vConsole.warn('Recursive NodeData detected for item', pNode)
                    return pCount;
                }
                if (pExpandByDefault || (this.expandedNodes && this.expandedNodes[pNode.$path.join('/')])) {
                    pNode.$expanded = true;
                }
                flatNodes.push(pNode);
                if (pDepth > maxDepth) {
                    maxDepth = pDepth;
                }
                this.itemsMap.set(pNode.$uid, pNode);
                pNode.$depth = pDepth;
                for (const node of pNode.$children) {
                    if (node.$type == 'item') { pCount++; }

                    node.$parentUid = pNode.$uid;
                    pCount += traverse(node, pDepth + 1);
                }

                if (pSetCount) {
                    pNode.$count = pCount;
                }

                if (!pSkipClientSideAggregates && hasAggregates && pNode.$type == 'summary') {
                    if (bulkAggregates[pDepth] == null) { bulkAggregates[pDepth] = []; }
                    bulkAggregates[pDepth].push(pNode);
                    // const aggregatesResult = await this.aggregateData(pNode.$children.map((item) => item.$item), pNode.$item, pNode);
                    // pNode.$item = { ...pNode.$item, ...aggregatesResult };
                }

                return pCount;
            }

            for (const node of pNodes) {
                count += traverse(node, pStartingDepth);
                if (node.$type == 'item') {
                    count += 1;
                }
            }

            if (!pSkipClientSideAggregates && hasAggregates) {
                for (let i = maxDepth; i >= 0; i--) {
                    const nodes = bulkAggregates[i];
                    if (nodes == null) { continue; }
                    const promises: Promise<void>[] = [];
                    for (const node of nodes) {
                        promises.push(this.aggregateData(node.$children.map((item) => item.$item), node.$item, node)
                            .then((aggregatesResult) => {
                                node.$item = { ...node.$item, ...aggregatesResult };
                            })
                        );
                    }
                    await Promise.all(promises);
                }
            }

            const sortFields = Object.keys(this.sortFields);
            const shouldResort = sortFields.some((field) => field in this.aggregates);
            if (shouldResort) {
                const sortScope = (pData: any[]) => {
                    if (sortFields.length) {
                        const sortFieldsArray = sortFields.map((field) => {
                            return this.sortFields[field];
                        }).sort((a, b) => a.sortOrder! - b.sortOrder!);
                        sortData({ fields: sortFieldsArray }, pData, this.sortFields, '$item');
                    }
                };

                flatNodes.length = 0;
                const traverseSort = (pNode: Node) => {
                    flatNodes.push(pNode);
                    sortScope(pNode.$children);
                    for (const node of pNode.$children) {
                        traverseSort(node);
                    }
                };

                sortScope(pNodes);
                for (const node of pNodes) {
                    traverseSort(node);
                }
            }

            return { flatNodes, maxDepth, count };
        }


        /**
         * Transform data to a node strucutre
         * @param pData - Input data array
         * @param pConfigIndex - Current processed config index
         */
        transformToNodeData(pData: any[], pConfigIndex: number, pParentPath: string[] = []): Node[] {
            const result: Node[] = [];
            if (pConfigIndex >= this.configurations.length) {
                for (const item of pData) {
                    const uid = crypto.randomUUID();
                    result.push({
                        $type: 'item',
                        $uid: uid,
                        $children: [],
                        $item: item,
                        $path: [...pParentPath, uid],
                        $configurationId: pConfigIndex - 1,
                    });
                }
                return result;
            }


            const sortFields = Object.keys(this.sortFields);
            if (sortFields.length) {
                const sortFieldsArray = sortFields.map((field) => {
                    return this.sortFields[field];
                }).sort((a, b) => a.sortOrder! - b.sortOrder!);
                sortData({ fields: sortFieldsArray }, pData, this.sortFields);
            }

            const config = this.configurations[pConfigIndex];
            if (config.type == 'groupBy') {
                let groupFieldIsSorted = false
                if (config.fieldName && sortFields.includes(config.fieldName)) {
                    groupFieldIsSorted = true;
                }
                if (config.fields && config.fields.findIndex((field) => sortFields.includes(field)) != -1) {
                    groupFieldIsSorted = true;
                }


                const groupMap: Record<string, Node> = {};
                for (const item of pData) {
                    const groupKey = this.getGroupKey(item, config);
                    if (groupMap[groupKey] == null) {
                        groupMap[groupKey] = {
                            $uid: crypto.randomUUID(),
                            $type: 'summary',
                            $children: [],
                            $path: [...pParentPath, groupKey],
                            $item: this.getGroupPartial(item, pConfigIndex),
                            $configurationId: pConfigIndex,
                        };
                    }
                    groupMap[groupKey].$children.push(item);
                }

                for (const groupKey in groupMap) {
                    const group = groupMap[groupKey];
                    group.$children = this.transformToNodeData(group.$children, pConfigIndex + 1, group.$path);
                    result.push(group);
                }

                if (groupFieldIsSorted) {
                    const sortFieldsArray = sortFields.reduce((arr, field) => {
                        if (config.fieldName == field) {
                            arr.push(this.sortFields[field]);
                        } else if (config.fields && config.fields.includes(field)) {
                            arr.push(this.sortFields[field]);
                        }
                        return arr;
                    }, [] as typeof this.sortFields[''][]).sort((a, b) => a.sortOrder! - b.sortOrder!);
                    sortData({ fields: sortFieldsArray }, result, this.sortFields, '$item');
                }


                if (config.pathMode && config.pathField) {
                    // Path mode is active for current group by configuration. Expand the result into paths by creating hierarchy summary nodes.
                    const groupedData = result.splice(0, result.length);
                    const hierarchyMap: Record<any, Node> = {};
                    for (const item of groupedData) {
                        const pathString = item.$item[config.pathField] as string;
                        const hasSlashSuffix = pathString.endsWith('/');
                        const hasSlashPrefix = pathString.startsWith('/');
                        const joinPath = (pPath: string[]) => {
                            let resultString = '';
                            if (hasSlashPrefix) {
                                resultString += '/'
                            }
                            resultString += pPath.join('/');
                            if (hasSlashSuffix) {
                                resultString += '/'
                            }
                            return resultString;
                        }
                        const path = this.getIdPathArray(pathString);
                        for (let i = 0; i < path.length; i++) {
                            const isLast = i == path.length - 1;
                            const id = path[i];
                            if (hierarchyMap[id] == null) {
                                if (!isLast) {
                                    hierarchyMap[id] = {
                                        $uid: crypto.randomUUID(),
                                        $type: 'summary',
                                        $children: [],
                                        $path: [...pParentPath, ...path.slice(0, i + 1)],
                                        $item: {
                                            [config.pathField]: joinPath(path.slice(0, i + 1))
                                        },
                                        $configurationId: pConfigIndex,
                                    };
                                } else {
                                    hierarchyMap[id] = {
                                        $uid: item.$uid,
                                        $type: 'summary',
                                        $children: item.$children,
                                        $path: [...pParentPath, ...path],
                                        $item: item.$item,
                                        $configurationId: pConfigIndex,
                                    };
                                }

                                if (i == 0) {
                                    result.push(hierarchyMap[id]);
                                } else {
                                    const previousId = path[i - 1];
                                    hierarchyMap[previousId].$children.push(hierarchyMap[id]);
                                }
                            }
                        }
                    }


                }
            } else if (config.type == 'hierarchy') {
                const existingIds = new Set<number>();
                const hierarchyMap: Record<any, Node> = {};
                const potentialRoots = new Set<string>();

                const hasPathRelations = !!config.idPathField;
                const hasDirectRelations = !!config.idField && !!config.parentField;
                if (hasPathRelations) {
                    config.idField = '$key';
                }

                /**
                 * Utility function for creating empty parent nodes in the map
                 * @param pId - Parent id
                 */
                const createParentNode = (pId: any) => {
                    if (config.requireParents && (this.hierarchyPartialsMap.has(pId) || this.hierarchyPartialsMap.has(+pId)) && !existingIds.has(+pId)) {
                        // Restore the parent item from previously loaded items cache
                        const partialItem = this.hierarchyPartialsMap.get(pId) ?? this.hierarchyPartialsMap.get(+pId)!;
                        hierarchyMap[pId] = {
                            $children: [],
                            $uid: crypto.randomUUID(),
                            $type: 'item',
                            $path: [pId],
                            $item: partialItem,
                            $configurationId: pConfigIndex,
                            $expanded: config.expandByDefault,
                        };
                        pData.push(partialItem);
                    } else {
                        // Create a summary parent item
                        hierarchyMap[pId] = {
                            $children: [],
                            $uid: crypto.randomUUID(),
                            $type: 'summary',
                            $path: [pId],
                            $item: {
                                [config.idField!]: pId,
                            },
                            $configurationId: pConfigIndex,
                            $expanded: config.expandByDefault,
                        };
                        potentialRoots.add(pId);
                    }
                };

                if (config.idField) {
                    for (const item of pData) {
                        const id = item[config.idField];
                        existingIds.add(id);
                    }
                }

                for (const item of pData) {
                    let id: any = undefined;
                    let parentId: any = undefined;

                    // Resolve id and parentId valus
                    if (hasPathRelations) {
                        const path = this.getIdPathArray(item[config.idPathField!]);
                        if (path.length == 1) {
                            id = path.join('/')
                        } else {
                            id = path.join('/');
                            parentId = path.slice(0, path.length - 1).join('/');
                            const pathParents = this.getJoinedPathsArray(path.splice(0, path.length - 2));
                            for (const pathParent of pathParents) {
                                if (hierarchyMap[pathParent] == null) {
                                    createParentNode(pathParent);
                                }
                            }
                        }
                        if (item[config.idField!] == null) {
                            item[config.idField!] = id;
                        }
                    } else if (hasDirectRelations) {
                        id = item[config.idField!];
                        parentId = item[config.parentField!];
                    } else {
                        throw new TypeError('NodeData hierarchy is missing idField with parentField or idPathField with idFIeld combinations. Cannot create a structure')
                    }

                    potentialRoots.delete(id);

                    if (hierarchyMap[id] == null) {
                        hierarchyMap[id] = {
                            $uid: crypto.randomUUID(),
                            $type: 'item',
                            $children: [],
                            $item: item,
                            $path: [id],
                            $configurationId: pConfigIndex,
                            $expanded: config.expandByDefault,
                        };
                    } else {
                        hierarchyMap[id].$type = 'item';
                        hierarchyMap[id].$item = item;
                    }

                    if (parentId) {
                        if (hierarchyMap[parentId] == null) {
                            createParentNode(parentId);
                        }
                        hierarchyMap[parentId].$children.push(hierarchyMap[id]);
                    } else {
                        result.push(hierarchyMap[id]);
                    }
                }

                for (const parentId of potentialRoots) {
                    const node = hierarchyMap[parentId];
                    if (config.requireParents) {
                        result.push(node);
                    } else {
                        for (const detail of node.$children) {
                            result.push(detail);
                        }
                    }
                }

                const visitedNodes = new Set<string>();
                const assignPath = (pNode: Node) => {
                    if (visitedNodes.has(pNode.$uid)) {
                        vConsole.warn('Circular structure detected. This node was already visited when assigning paths', pNode)
                        return;
                    }
                    visitedNodes.add(pNode.$uid);
                    for (const detail of pNode.$children) {
                        const id = detail.$item[config.idField!];
                        detail.$path = [...pNode.$path, id];
                        assignPath(detail);
                    }
                };

                for (const node of result) {
                    const id = node.$item[config.idField!];
                    node.$path = [...pParentPath, id];
                    assignPath(node);
                }
            }

            return result;
        }

        /**
         * Get group by key for an item and configuration
         * @param pItem - Item for which to generate the group by key
         * @param pConfiguration - Configuration used to generate the group by key 
         */
        getGroupKey(pItem: any, pConfiguration: NodeDataConfigurationOptions) {
            if (pConfiguration.type === 'hierarchy') {
                throw new Error('Cannot construct group by key for hierarchy configuration')
            }

            let keyItem: Record<string, any> = {};
            if (pConfiguration.fieldName) {
                keyItem[pConfiguration.fieldName] = pItem[pConfiguration.fieldName];
            }
            if (pConfiguration.fields) {
                for (const field of pConfiguration.fields) {
                    keyItem[field] = pItem[field];
                }
            }
            return JSON.stringify(keyItem);
        }

        /**
         * Get partial summary values for a group item 
         * @param pItem - Item from which to grab the values
         * @param pConfigIndex - Configuration index till which the values will be grabbed
         */
        getGroupPartial(pItem: any, pConfigIndex: number) {
            const partialItem: any = {};
            if (pItem.$properties) {
                partialItem.$properties = pItem.$properties;
            }
            for (let index = 0; index <= pConfigIndex; index++) {
                if (index >= this.configurations.length) { break; }
                const config = this.configurations[index];
                if (config.type == 'groupBy') {
                    if (config.fieldName) {
                        partialItem[config.fieldName] = pItem[config.fieldName];
                    }
                    if (config.fields) {
                        for (const field of config.fields) {
                            partialItem[field] = pItem[field];
                        }
                    }
                    if (config.pathMode && config.pathField) {
                        partialItem[config.pathField] = pItem[config.pathField];
                    }
                }
            }
            return partialItem;
        }

        /**
         * Get binding where clause for loading details
         * @param pNodes - Array of parent nodes from which the clause will be generated
         */
        getBindingClause(pNodes: Node[]) {
            let clauses: string[] = [];

            const getClause = (pField: string, pValue: any) => {
                // TODO: Add field type for correct clauses construction
                if (pValue == null) {
                    return `[${pField}] IS NULL`;
                } else {
                    return `[${pField}] = '${pValue}'`;
                }
            }

            let previousConfiguration: NodeDataConfigurationOptions | undefined = undefined;
            let previousNode: Node | undefined = undefined;
            for (const node of pNodes) {
                const configuration = this.configurations[node.$configurationId!];
                if (configuration.type == 'hierarchy') {
                    if (previousConfiguration?.type == 'hierarchy') {
                        clauses.pop();
                    }

                    if (previousNode) {
                        clauses.push(`[${configuration.parentField}] = '${previousNode.$item[configuration.idField!]}'`)
                    } else {
                        clauses.push(`[${configuration.parentField}] IS NULL`)
                    }

                } else {
                    if (configuration.fieldName) {
                        clauses.push(getClause(configuration.fieldName, node.$item[configuration.fieldName]));
                    }
                    if (configuration.fields) {
                        for (const field of configuration.fields) {
                            clauses.push(getClause(field, node.$item[field]));
                        }
                    }
                }
                previousConfiguration = configuration;
                previousNode = node;
            }

            return clauses.join(' AND ');
        }

        /**
         * Get fields for a server side group by load.
         * @param pFields - Original fields from the record source options
         * @param pParentNodes - Parent nodes from wich the group configurations will be retrieved
         */
        getFieldsForServerSideLoad(pMode: 'group' | 'normal', pConfiguration: NodeDataConfigurationOptions, pParentNodes: Node[]) {
            const fields: RecordSourceOptions['fields'] = [];
            if (pMode == 'normal' || pConfiguration.type == 'hierarchy') {
                for (const field of this.structureFields) {
                    if (field.startsWith('Property.')) {
                        throw new Error('Properties group by is only available when loadFullStructure is enabled');
                    } else {
                        fields.push({ name: field });
                    }
                }
                return fields;
            }


            const groupByConfigurations: NodeDataConfigurationOptions[] = [];
            if (pParentNodes.length) {
                for (const node of pParentNodes) {
                    const configuration = this.configurations[node.$configurationId!];
                    if (configuration.type == 'groupBy') {
                        groupByConfigurations.push(configuration);
                    }
                }
            }
            groupByConfigurations.push(pConfiguration);

            let addedFields = new Set<string>();
            for (let index = 0; index < groupByConfigurations.length; index++) {
                const configuration = groupByConfigurations[index];
                if (configuration.type == 'hierarchy') { continue; }

                if (configuration.fieldName) {
                    if (addedFields.has(configuration.fieldName)) { continue; }
                    fields.push({
                        name: configuration.fieldName,
                        groupByOrder: index + 1
                    });
                }
                if (configuration.fields) {
                    for (const field of configuration.fields) {
                        if (addedFields.has(field)) { continue; }
                        fields.push({
                            name: field,
                            groupByOrder: index + 1
                        });
                    }
                }
            }

            for (const key in this.aggregates) {
                if (addedFields.has(key)) { continue; }
                const aggregate = this.aggregates[key];
                if (aggregate.aggregate == 'CUSTOM') { continue; }
                fields.push({
                    name: aggregate.name,
                    aggregate: aggregate.aggregate
                });
            }

            fields.push({
                name: this.uniqueKeyField,
                alias: '__count__',
                aggregate: 'COUNT'
            });

            return fields;
        }

        /**
         * Split the id path into parts and filter out empty values
         * @param pIdPath - Id path string
         */
        getIdPathArray(pIdPath: string) {
            const parts = pIdPath.split('/');
            const result: string[] = [];
            for (const id of parts) {
                if (id.length) {
                    result.push(id);
                }
            }
            return result;
        }

        /**
         * Transform path array parts into joined paths
         * @param pPath - Array of path parts
         * 
         * @example
         * // returns ['a', 'a/b', 'a/b/c']
         * getJoinedPathsArray(['a','b','c'])
         */
        getJoinedPathsArray(pPath: string[]) {
            const result: string[] = [];
            let currentPath = '';
            for (const path of pPath) {
                currentPath = currentPath ? `${currentPath}/${path}` : path;
                result.push(currentPath);
            }
            return result;
        }
        /**
         * Resolve details configuration index from a node object
         * @param pNode - Node object from which to resolve the details configuration index
         */
        resolveDetailsConfigurationIndex(pNode?: Node) {
            let index = -1;
            let isLast = false;
            if (pNode) {
                const configurationIndex = pNode.$configurationId!;
                if (this.configurations[configurationIndex + 1]) {
                    index = configurationIndex + 1;
                } else {
                    isLast = true;
                    index = configurationIndex;
                }
            } else {
                index = 0;
            }

            return { index: index, isLast: isLast };
        }

        /**
         * Helper function for storing previous loaded structure items
         * @param pData - Input items array
         * @param pConfiguration - Configuration options
         */
        storePreviousPartialsForHierarchy(pData: any, pConfiguration: NodeDataConfigurationOptions) {
            if (pConfiguration.type == 'hierarchy' && pConfiguration.requireParents) {
                this.hierarchyPartialsMap.clear();
                if (pConfiguration.idPathField) {
                    const idPathField = pConfiguration.idPathField;
                    for (const item of pData) {
                        const path = this.getIdPathArray(item[idPathField]);
                        const id = path.join('/');
                        this.hierarchyPartialsMap.set(id, item);
                    }
                } else if (pConfiguration.idField) {
                    const idField = pConfiguration.idField;

                    for (const item of pData) {
                        const id = item[idField];
                        this.hierarchyPartialsMap.set(id, item);
                    }
                }
            }
        }


        /**
         * Apply sort fields to record source selected fields 
         * @param pFields - Record source fields for retrieval
         */
        applySortFields(pFields: NonNullable<RecordSourceOptions['fields']>, pDropSort?: boolean) {
            const usesGroupBy = pFields.some((field) => field.groupByOrder != null);
            for (const key in this.sortFields) {
                const sortField = this.sortFields[key];
                if (sortField.name.startsWith('Property.')) { continue; }
                const field = pFields?.find((x) => x.name == sortField.name);
                if (field) {
                    field.sortDirection = sortField.sortDirection as any;
                    field.sortOrder = sortField.sortOrder as any;
                } else {
                    pFields.push({
                        name: sortField.name,
                        sortDirection: sortField.sortDirection as any,
                        sortOrder: sortField.sortOrder as any,
                        aggregate: usesGroupBy ? 'MIN' : undefined
                    });
                }
            }

            if (pDropSort) {
                for (const field of pFields) {
                    delete field.sortDirection;
                    delete field.sortOrder;
                }
            }
        }

        /**
         * Aggregate data
         * @param pData - Array of items to aggregate
         */
        async aggregateData(pData: any[], pItem: any, pNode: Node) {
            if (Object.keys(this.aggregates).length == 0) { return {}; }
            const aggregateFunctions = {
                COUNT: (data: any[], field: string) => data.reduce((count, item) => {
                    if (item[field] != null) { count++; }
                    return count;
                }, 0),
                SUM: (data: any[], field: string) => data.reduce((sum, item) => {
                    if (item[field] != null) { sum += item[field]; }
                    return sum;
                }, 0),
                AVG: (data: any[], field: string) => {
                    const dataSum = data.reduce((sum, item) => {
                        if (item[field] != null) { sum += item[field]; }
                        return sum;
                    }, 0);
                    return dataSum / data.length;
                },
                MIN: (data: any[], field: string) => data.reduce((min, item) => {
                    if (item[field] != null) {
                        if (min == null || item[field] < min) {
                            min = item[field];
                        }
                    }
                    return min;
                }, null),
                MAX: (data: any[], field: string) => data.reduce((max, item) => {
                    if (item[field] != null) {
                        if (max == null || item[field] > max) {
                            max = item[field];
                        }
                    }
                    return max;
                }, null)
            };

            const result: any = {};
            for (const field in this.aggregates) {
                const aggregate = this.aggregates[field];
                if (aggregate.aggregate == 'CUSTOM') {
                    const item = { ...pNode, $item: pItem, $children: pData };
                    // Run custom aggregate on main thread
                    result[aggregate.name] = await this.channel.execute('customAggregate', { data: pData, field: aggregate.name, item: item });
                } else {
                    result[aggregate.name] = aggregateFunctions[aggregate.aggregate](pData, aggregate.name) as any;
                }
            }
            return result;
        }
    }


    return { WorkerMessageChannel, sortData, NodeDataWorker };
}

async function init(pId: string, pPort: MessagePort) {
    const { NodeDataWorker } = await getWorkerImportModules();;

    const nodeDataWorker = new NodeDataWorker({
        id: pId,
        port: pPort
    });
}
