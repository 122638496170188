<template>
    <div :id="chartID"></div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import { getOrCreateProcedure } from 'o365-modules';
import { urlFilter } from 'o365-filterobject';

const props = defineProps({
    chartConfig: {
        type: String,
        required: true,
        default: null
    },
    procedure: {
        type: String,
        required: true,
        default: null
    },
    procedureParams: {
        type: Object,
        required: false,
        default: null
    },
    dashboardUrl: {
        type: String,
        required: false,
        default: null
    }
});

const emit = defineEmits(["loaded"]);

var loadDynamicChartProc = {};
const chartID = ref("chart_" + crypto.randomUUID());
let chart = null;

function load() {
    if (!props.procedure) return
    loadDynamicChartProc = getOrCreateProcedure({ id: props.procedure, procedureName: props.procedure, useAlert: false, useGroupedRequests: false });

    loadDynamicChartProc.execute(getProcedureParams()).then(async (response) => {
        let data;
        if (response.hasOwnProperty("Table")) {
            data = response.Table;
        } else {
            data = response;
        }

        const options = JSON.parse(props.chartConfig);
        window.options = options;
        window.data = data;
        const categoryColumn = options.categoryColumn.name;
        const isDistinct = options.categoryColumn.distinct;
        
        delete options.categoryColumn;
        delete options.seriesColumns;

        if (options.xAxis == null) { options.xAxis = {} };

        // If data will be datetime it must be converted to timestamp for format to be working.
        if (options.xAxis.type === 'datetime') {
            data.forEach(item => {
                let timeStamp = new Date(item[categoryColumn]).getTime();
                item[categoryColumn] = isNaN(timeStamp) ? 0 : timeStamp;
            })
        };

        handleSeriesColors(options.series);
        handleDataSorting(options.xAxis, data, categoryColumn);

        if (options.chart.type == 'pie') {
            for (let i = 0; i < data.length; i++) {
                options.series[0].data.push({
                    name: data[i][categoryColumn],
                    y: data[i][options.series[0].name]
                })
            }
            options.series[0].name = categoryColumn;
        } else {
            if (isDistinct) {
                let newSeriesNames: Array<string> = [];
                let categoryList: Array<string> = [];
                data.forEach((i) => {
                    if (newSeriesNames.filter(a => a == i[options.series[0].name]).length == 0) {
                        newSeriesNames.push(i[options.series[0].name]);
                    }
                    if (categoryList.filter(a => a == i[categoryColumn]).length == 0) {
                        categoryList.push(i[categoryColumn]);
                    }
                });

                let newSeries: Array<Object> = [];
                
                // Currently only first series is supported when distinct is used.
                newSeriesNames.forEach((seriesName) => {
                    let serieObject: Object = {
                        ...options.series[0],
                        name: seriesName,
                        data: []
                    }
                    
                    // If to use color coming from procedure
                    let colorToUse = null;

                    categoryList.forEach((category) => {
                        let dataObjs = data.filter(row => row[categoryColumn] == category && row[options.series[0].name] == seriesName);
                        if (dataObjs.length > 0) {
                            let totalValue = dataObjs.reduce((sum, dataObj) => {
                                let value = dataObj[options.series[0].valueColumn];

                                return sum + value;
                            }, 0);

                            if (options.series[0].dataType === 'percent') {
                                totalValue = totalValue * 100;
                            }

                            if (serieObject.useDefaultColor === false && serieObject.colorColumn) {
                                colorToUse = dataObjs[0][serieObject.colorColumn];
                            }

                            serieObject.data.push(totalValue);
                        } 
                        else {
                            serieObject.data.push(null);
                        }
                    });

                    if (serieObject.data && (serieObject.type == 'waterfall' || options.chart.type == 'waterfall') && serieObject.showWaterfallTotal) {
                        serieObject.data.push({
                            isSum: true
                        });
                        categoryList.push('Total');
                    }

                    // Assign the color that was found
                    if (colorToUse) {
                        serieObject.color = colorToUse;
                    }
                    
                    newSeries.push(serieObject)
                });

                options.series = newSeries;
                options.xAxis.categories = categoryList;
            } else {
                options.series.forEach(seriesColumn => {
                    if (seriesColumn.valueColumn) {
                        seriesColumn.data = data.map(item => item[seriesColumn.valueColumn]);
                    } else {
                        seriesColumn.data = data.map(item => item[seriesColumn.name]);
                    }
                    if (seriesColumn.dataType === 'percent') {
                        seriesColumn.data = seriesColumn.data.map(value => value * 100);
                    }

                    if ((seriesColumn.type == 'waterfall' || options.chart.type == 'waterfall') && seriesColumn.showWaterfallTotal) {
                        seriesColumn.data.push({
                            isSum: true
                        });
                    }

                    if (seriesColumn.animation == null) {
                        seriesColumn.animation = {
                            duration: 500,
                            easing: 'easeOutBounce'
                        };
                    }
                });
                options.xAxis.categories = data.map(item => item[categoryColumn]);

                const hasWaterfallSum = options.series?.some(series => series?.showWaterfallTotal && (series?.type == 'waterfall' || options.chart.type == 'waterfall' ));
                if (hasWaterfallSum) {
                    options.xAxis.categories.push('Total');
                }
            }
        }
        chart = Highcharts.chart(chartID.value, options);

        const hasCustomURLEnabled = options.series?.some(series => series?.customURL?.enabled);
        if (hasCustomURLEnabled) {
            chart.update({ plotOptions: { series: { point: { events: { click: function(value) { return getItemUrl(value, data, options, categoryColumn) } } } } } })
        }


        emit("loaded");
    }).catch((err) => {
        console.log(err)
    });

}

async function getItemUrl(value, data, options, categoryColumn) {
    const series = options.series[value.point.series.index];

    if (series.customURL?.enabled) {
        var foundItem = data.find((obj) => obj[categoryColumn] === value.point.category);

        const url = "/" + series.customURL.appId + "?filtermode=all&" + 
            (await urlFilter.getCompressedUrlFilterString("" + series.customURL.filterColumn + " " + series.customURL.operator + " (" + foundItem[series.customURL.filterValue] + ")",
             series.customURL.appId == 'scope-items' ? 'scope-library' : series.customURL.appId, series.customURL.dataObject));

        if (url) {
            window.open(window.location.origin + url, '_blank');
        }
    }
}

function getProcedureParams() {
    if (props.procedureParams) {
        let cleanedUpObj = {};
        Object.entries(props.procedureParams).forEach(([key, value]) => {
            if (value) {
                cleanedUpObj[key] = value
            } else {
                cleanedUpObj[key] = null

            }
        });
        return cleanedUpObj
    } else if (props.dashboardUrl) {
        let procParams = {};
        new URLSearchParams(props.dashboardUrl.split("?")[1]).forEach((value, key) => {
            if (key == "name") return; // to not pass the chart name as param
            procParams[key] = value;
        });
        return procParams
    } else {
        return {}
    }
}

function handleSeriesColors(series) {
    series.forEach(seriesColumn => {
        if (seriesColumn.useDefaultColor) {
            delete seriesColumn.color;
        }
    });
}

function handleDataSorting(xAxis, data, categoryColumn) {
    if (!xAxis.sortOrder) {
        return;
    }

    if (xAxis.sortOrder === 'asc') {
        data.sort((a, b) => (a[categoryColumn] > b[categoryColumn] ? 1 : -1));
    } else if (xAxis.sortOrder === 'desc') {
        data.sort((a, b) => (a[categoryColumn] < b[categoryColumn] ? 1 : -1));
    }
}

watch(() => props.procedureParams, () => {
    if (chartID.value) {
        load();
    }
}, { deep: true })

onMounted(() => {
    load();
})
</script>