import { h } from 'vue';
import { createApp } from 'o365-vue';
import { getOrCreateDataObject } from 'o365-dataobject';
import { SyncDefinition } from 'o365.pwa.modules.client.SyncDefinition.ts';
import { default as ServiceWorkerRegistration } from 'o365.pwa.modules.client.ServiceWorkerRegistration.ts';
import { InjectionKeys } from 'o365-utils';
import { hasQueryParameter, getQueryParameter, deleteQueryParameter } from 'o365.modules.utils.url.ts';
import { app, userSession } from 'o365-modules';
import { cdnBaseUrl } from 'o365.modules.helpers.js';
import { AppStepDefinition } from 'o365.pwa.modules.client.steps.AppStepDefinition.ts';
import { GroupStepDefinition } from 'o365.pwa.modules.client.steps.GroupStepDefinition.ts';
import { $t } from 'o365-utils';
import { isSyncDefinitionId } from 'o365.pwa.types.ts';
import DataObjectInitializer from 'o365.pwa.modules.client.DataObjectInitializer.ts';

import IndexedDBHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';
import { pwaStore, type IPWAStoreOptions as IOfflineStoreOptions, type IPWAStore } from 'o365.pwa.modules.client.PWAStore.ts';

import type Database from 'o365.pwa.modules.client.dexie.objectStores.Database2.ts';
import type ObjectStore from 'o365.pwa.modules.client.dexie.objectStores.ObjectStore.ts';
import type Index from 'o365.pwa.modules.client.dexie.objectStores.Index.ts';
import 'o365.dataObject.extension.Offline.ts';

// VUE Components
import PWA from 'o365.pwa.vue.components.PWA.vue';

export interface IServiceWorkerOptions {
    useDefaultScripts: boolean;
    extraScripts?: Array<string | URL>;
}

export interface IOfflineOptions {
    entrypoint?: string;
    vueApps: Array<any>;
    syncDefinitions: Map<string, SyncDefinition>;
    serviceWorkerOptions?: IServiceWorkerOptions;
    offlineStoreOptions?: IOfflineStoreOptions;
}

const { pwaStoreKey } = InjectionKeys;

const defaultOfflineOptions = <IOfflineOptions>{
    syncDefinitions: new Map(),
    serviceWorkerOptions: {
        useDefaultScripts: true
    },
    offlineStoreOptions: {
        enableServerCheck: true
    }
}

export namespace OfflineInitializer {
    export async function initializeOffline(providedOptions: IOfflineOptions): Promise<IPWAStore> {
        if (!window.location.pathname.includes("/nt/")) {
            window.location.pathname = `/nt${window.location.pathname}`;
        }

        let persisted = await window.navigator.storage.persisted();

        const options = Object.assign({}, defaultOfflineOptions, providedOptions);

        if (!options.syncDefinitions.has('Install-App')) {
            const installAppSyncDefinition = new SyncDefinition({
                syncType: 'OFFLINE-SYNC',
                title: $t('Installing app'),
                steps: [
                    new GroupStepDefinition({
                        stepId: 'Group',
                        steps: [
                            new AppStepDefinition({
                                stepId: 'Install App',
                            }),
                        ],
                    }),
                ],
                runWithoutUI: false,
                autoCloseDialogOnSuccess: true
            });

            options.syncDefinitions.set('Install-App', installAppSyncDefinition);
        }

        if (persisted === false) {
            await window.navigator.storage.persist();

            // TODO: Reimplement persisted check
            // Issue last time was that some devices did not remember user choice

            // persisted = await window.navigator.storage.persisted();

            // if (persisted === false) {
            //     const message = 'This app stores data for offline usage. It is recommended that persistent storage is allowed so data is not lost.';

            //     alert(message, ToastType.Warning, { autohide: true, delay: 5000 });
            // }
        }

        let pwaContainerElement = document.getElementById('pwa-container');

        if (pwaContainerElement === null) {
            pwaContainerElement = document.createElement('div')

            pwaContainerElement.id = 'pwa-container';

            document.body.append(pwaContainerElement);
        }

        const pwaVueApp = await createApp(
            {
                name: 'O365_PWA',
                render: () => {
                    return h(PWA);
                }
            },
            {
                includeProperties: true,
                includeComponents: false,
                includeDirectives: false
            }
        );

        await pwaStore.initialize('', '', options.syncDefinitions, options.offlineStoreOptions, () => pwaVueApp);

        const pwaComponents = new Map<string, any>;

        for (const syncDefinition of options.syncDefinitions.values()) {
            for (const syncStep of syncDefinition.steps) {
                const vueComponentName = syncStep.vueComponentName;
                const vueComponentImportCallback = syncStep.vueComponentImportCallback;

                const importVueComponent = async (vueComponentName: any, vueComponentImportCallback: any) => {
                    const pwaComponent = pwaComponents.get(vueComponentName);

                    if (pwaComponent === undefined) {
                        const vueComponent = await vueComponentImportCallback();

                        pwaComponents.set(vueComponentName, vueComponent.default);
                    }
                }

                await importVueComponent(vueComponentName, vueComponentImportCallback);

                const subVueComponentsDefinitions = syncStep.subVueComponentsDefinitions;

                if (subVueComponentsDefinitions === undefined) {
                    continue;
                }

                for (const subVueComponentDefinition of subVueComponentsDefinitions) {
                    const vueComponentName = subVueComponentDefinition.vueComponentName;
                    const vueComponentImportCallback = subVueComponentDefinition.vueComponentImportCallback;

                    await importVueComponent(vueComponentName, vueComponentImportCallback);
                }
            }
        }

        for (const pwaComponent of pwaComponents.values()) {
            pwaVueApp.component(pwaComponent.name, pwaComponent);
        }

        pwaVueApp.provide(pwaStoreKey, pwaStore);

        pwaVueApp.mount('#pwa-container');

        for (const vueApp of options.vueApps) {
            vueApp.provide(pwaStoreKey, pwaStore);
        }

        let idbApp = await IndexedDBHandler.getApp(app.id);

        if (idbApp === null) {
            idbApp = await IndexedDBHandler.createApp(app.id,);
        }

        let title = app.config?.pwaSettings?.title ?? app.id,
            icon = app.config?.pwaSettings?.icon ?? 'bi bi-question-lg';

        if (idbApp.title !== title || idbApp.icon !== icon) {
            idbApp.title = title;
            idbApp.icon = icon;

            await idbApp.save();
        }

        const indexedDbDatabases = new Map<string, {
            value: Database,
            objectStores: Map<string, {
                value: ObjectStore,
                indexes: Map<string, {
                    value: Index
                }>
            }>
        }>();

        indexedDbDatabases.set('DEFAULT', {
            value: (await idbApp.databases['DEFAULT']) ?? (await IndexedDBHandler.createDatabase(idbApp.id, 'DEFAULT')),
            objectStores: new Map()
        });

        let user = await IndexedDBHandler.getUser();

        if (user === null && typeof userSession.personId === 'number') {
            user = await IndexedDBHandler.createUser(userSession.personId, userSession);
        } else if (user !== null) {
            user.userSession = userSession;

            await user.save();
        }

        let globalSetting = await IndexedDBHandler.getGlobalSetting();

        if (globalSetting === null) {
            globalSetting = await IndexedDBHandler.createGlobalSetting(cdnBaseUrl);
        }

        let userDevice = await IndexedDBHandler.getUserDevice();

        if (!userDevice) {
            await IndexedDBHandler.createUserDevice(Object.assign({
                deviceInfoString: window.navigator.userAgent
            }));
        }

        /**
         * TODO: Create system to create diff against installed schema
         *          Including Databases, Object Stores and Indexes.
         *          This system must also check against shared object stores or global object stores
         */

        const dataObjectConfigsFromObjectStores = Array.from(await IndexedDBHandler.getObjectStores(app.id, "DEFAULT")).filter((store) => store.initializeDataObject && store.dataObjectConfig).map((record) => record.dataObjectConfig);
        if (dataObjectConfigsFromObjectStores) {
            for (let config of dataObjectConfigsFromObjectStores) {
                if (config && config.id) {
                    app.dataObjectConfigs.set(config.id, config);
                    const dataObject = getOrCreateDataObject(config, app.id);
                    dataObject.enableOffline();
                }
            }
        }
        
        await DataObjectInitializer.initializeDataObjects(app.dataObjectConfigs);

        // TODO: We need a system to remove object stores no longer in use as well as databases no longer in use...

        await idbApp.initialize();

        // Register console events from service worker
        window.addEventListener('message', (event: MessageEvent) => {
            try {
                const message = event.data;

                if (typeof message !== 'string') {
                    return;
                }

                const messageJson = JSON.parse(message);

                switch (messageJson.type) {
                    case 'ConsoleOperation':
                        const consoleMethod = messageJson.method;

                        switch (consoleMethod) {
                            case 'log':
                                window['console'].log(...messageJson.args);
                                break;
                            case 'error':
                                window['console'].error(...messageJson.args);
                                break;
                            case 'warn':
                                window['console'].warn(...messageJson.args);
                                break;
                            case 'info':
                                window['console'].info(...messageJson.args);
                                break;
                        }

                        break;
                }
            } catch (error) {
                window['console'].error(error);
            }
        });

        const o365ServiceWorkerRegistration = new ServiceWorkerRegistration({ type: 'classic' });

        await pwaStore.setServiceWorkerRegistration(o365ServiceWorkerRegistration);

        if (hasQueryParameter('pwa-continue-sync')) {
            const syncId = isSyncDefinitionId(getQueryParameter('pwa-continue-sync') ?? '');

            deleteQueryParameter('pwa-continue-sync');

            if (syncId) {
                await pwaStore.startSync(syncId, true);
            }
        }

        return pwaStore;
    }
}

export default OfflineInitializer;
