/**********************************************************************/
/* ----        IMPORTANT! Read before making any changes         ---- */
/**********************************************************************/
/* ----           This file is part of a set of files            ---- */
/* ----            Any changes here MUST be added to:            ---- */
/**********************************************************************/
/* - o365.pwa.declaration.shared.dexie.databases.O365PWAGlobal.d.ts   */
/* - o365.pwa.modules.client.dexie.databases.O365PWAGlobal.ts         */
/* - o365.pwa.modules.sw.dexie.databases.O365PWAGlobal.ts             */
/**********************************************************************/

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { IFileStoreRecordOptions } from 'o365.pwa.declaration.sw.dexie.objectStores.FileStoreFile.ts';
import type { FileStoreRecord } from 'o365.pwa.declaration.sw.dexie.objectStores.FileStoreFile.ts';

declare var self: IO365ServiceWorkerGlobalScope;

declare module 'o365.pwa.declaration.shared.dexie.databases.O365PWAGlobal.d.ts' {
    export class O365PWAGlobal extends self.Dexie.latestVersion.Dexie {
        constructor();

        retrieveFileStoreRecord(primKey: string, appId: string): Promise<FileStoreRecord | null>;
        retrieveFileStoreRecords(primaryKeys: Array<[ primKey: string, appId: string ]>): Promise<Array<FileStoreRecord | null>>;
        createFileStoreRecord(fileStoreRecordOptions: IFileStoreRecordOptions): Promise<void>;
        bulkCreateFileStoreRecords(fileStoreRecordsOptions: Array<IFileStoreRecordOptions>): Promise<void>;
        updateFileStoreRecord(fileStoreRecord: FileStoreRecord): Promise<void>;
        bulkUpdateFileStoreRecords(fileStoreRecords: Array<FileStoreRecord>): Promise<void>;
        destroyFileStoreRecord(primKey: string, appId: string): Promise<void>;
        bulkDestroyFileStoreRecords(primaryKeys: Array<[ primKey: string, appId: string ]>): Promise<void>;
        bulkDestroyFileStoreRecordsByAppId(appId: string): Promise<void>;
    }

    export const o365PWAGlobal: {
        readonly instance: O365PWAGlobal;
    }
}
