import type { Index } from 'o365.pwa.declaration.shared.dexie.objectStores.Index.d.ts';

declare module "o365.pwa.declaration.shared.dexie.objectStores.ObjectStore.d.ts" {
    export interface IObjectStoreUpdate {
        schema: string;
        objectStoreName: string;
    }
    export interface IDataObjectConfig {
        id: string;
        viewName: string;
        fields: { name: string }[]
        offline: {
            enableOffline: boolean,
            jsonDataVersion: number,
            objectStoreIdOverride: string,
            generateOfflineData: boolean,
            appIdOverride: string | null,
            databaseIdOverride: string | null,
            generateOfflineDataProcedureNameOverride: string | null
        }
    }

    export interface IIndexes {
        getAll: () => Promise<Array<Index>>;
    }

    export type Indexes = IIndexes & Record<Exclude<string, keyof IIndexes>, Promise<Index>>;

    export class ObjectStore {
        static objectStoreDexieSchema: string;

        public id: string;
        public appId: string;
        public databaseId: string;
        public jsonDataVersion: number | null;
        public fields: Array<string> | null;
        public isPropertyTable: boolean;
        public dataObjectConfig?: IDataObjectConfig;
        public initializeDataObject?: boolean;


        constructor(id: string, databaseId: string, appId: string, jsonDataVersion: number | null, fields: Array<string> | null, isPropertyTable: boolean, dataObjectConfig: IDataObjectConfig | undefined, initializeDataObject: boolean);

        public readonly indexes: Indexes;
        public readonly schema: Promise<string>;

        public save(): Promise<void>;
        public delete(): Promise<void>;
        public forceReload(): Promise<ObjectStore | null>;
    }
}
