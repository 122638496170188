import type { IFileStoreRecordOptions, FileStoreRecord } from 'o365.pwa.declaration.shared.dexie.objectStores.FileStoreFile.d.ts';

declare module 'o365.pwa.declaration.sw.FileCrudHandler.d.ts' {
    export interface IHTTPCONTENTRANGE {
        unit: string;    // In most cases, this is "bytes"
        start: number;   // The start byte position
        end: number;     // The end byte position
        total: number;   // The total size of the resource
    }

    export interface IViewOptions {
        FileRef: string;
        appId: string;
    }

    export interface IDownloadOptions {
        FileRef: string;
        appId: string;
    }

    export interface IUploadOptions {
        PrimKey?: string;
        Data: ArrayBuffer | Blob;
        MimeType: string;
        FileName: string;
        FileSize: number;
        Extension: string;
        PdfRef?: string;
        ThumbnailRef?: string;
        OriginalRef?: string;
        appID: string;
    }

    export interface IChunkUploadOptions {
        data: Blob;
        filename: string;
        extension: string;
        mimeType: string;
        extraValues: {
            [key: string]: unknown;
        };
        uploadRef?: string;
        appID: string;
    }

    export interface IChunkUploadResponse {
        action: string;
        fileRef: string | null;
        uploadRef: string;
        chunks: Array<{
            ChunkCRC: number | null;
            ChunkGuid: string;
            ChunkPosition: number;
            ChunkSize: number;
            Chunk_ID: number;
        }>;
        row: Array<{ PrimKey: string }>;
    }

    export interface IPdfViewOptions {
        FileRef: string;
        appId: string;
    }

    export interface IFileUpdateOptions {
        OriginalRecord: FileStoreRecord;
        NewValues: IFileStoreRecordOptions;
    }

    // Define the FileCrudHandler type
    export interface FileCrudHandler {
        handleView: (options: IViewOptions) => Promise<FileStoreRecord | null>;
        handleDownload: (options: IDownloadOptions) => Promise<FileStoreRecord | null>;
        handleUpload: (options?: IUploadOptions, primkey?: string, appID?: string) => Promise<FileStoreRecord | null>;
        handleChunkUpload: (options: IChunkUploadOptions, headers: Map<string, Set<string>>) => Promise<IChunkUploadResponse>;
        handleChunkSetCRC: (options: { FileCRC: number; FileName: string; FileSize: number; UploadRef: string; ViewName: string; appId: string; }) => Promise<{ action: string; }>;
        handlePdfView: (options: IPdfViewOptions) => Promise<FileStoreRecord | null>;
        handlePdfDownload: (options: IPdfViewOptions) => Promise<FileStoreRecord | null>;
        handleFileUpdate: (OriginalRecord: FileStoreRecord, NewValues: Partial<IFileStoreRecordOptions>, appId: string) => Promise<FileStoreRecord | null>;
        combineBlobs: (blob1: Blob | ArrayBuffer, blob2: Blob) => Promise<Blob>;
        blobToArrayBuffer: (blob: Blob) => Promise<ArrayBuffer>;
        getHeaderValues: (key: string, headers: Map<string, Set<string | number>>) => string | number | null;
        getContentRange: (contentString: string | null) => IHTTPCONTENTRANGE | null;
    }

    export const FileCrudHandler: FileCrudHandler;
}

export { }
