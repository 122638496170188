<script setup>
import { ref, computed } from 'vue';
import { getDataObjectById } from 'o365-dataobject';
import { procSJAWorkflowAction } from 'sja.vue.libraries.modules.utilities.js'
import { getOrCreateProcedure } from 'o365-modules';
import { alert } from 'o365-vue-services';
import { OActionList, OActionItem } from "o365-ui-components";

const props = defineProps({
    actionRow: Object,
    sjaID: Number,
    readOnly: Boolean
});

const procSendActionReminder = getOrCreateProcedure({ id: "procSendActionReminder", procedureName: "astp_SJA_SendTaskEmail" });
let dsResponsible = getDataObjectById(`dsTasksHazardsActionsResp_${props.sjaID}`);
let dsSJA = getDataObjectById(`dsSJA_${props.sjaID}`)
let isHidden = ref(false);

const responsibleData = computed(() => {
    return dsResponsible.data.filter(item => item.SJATasksHazardsAction_ID == props.actionRow.ID);
});

async function createWorkflowAction(responsible, actionRow, stepID) {
    isHidden.value = true;
    try {
        await procSJAWorkflowAction.execute({ Step_ID: stepID, Responsible_ID: responsible.Responsible_ID, ResponsibleRow_ID: responsible.ID, Action_Title: actionRow.Name, Action_Comment: actionRow.Comment });
    }
    finally {
        await dsResponsible.load();
        isHidden.value = false;
    }
}

function sendReminder(person) {
    procSendActionReminder.execute({ SJA_ID: person.SJA_ID, Person_ID: person.Responsible_ID, SJATasksHazardsAction_ID: person.SJATasksHazardsAction_ID, TypeCode: 'SJA_ACTION_REMINDER' })
        .then(() => {
            alert($t('Reminder e-mail has been sent'), 'success', { autohide: true, delay: 5000 });
        })
        .catch((e) => {
            alert($t('An error has occurred, ') + e);
        });
}

async function removeResponsible(row) {
    if(confirm($t("Remove person") + '\n' + $t('Are you sure you wish to remove') + ' ' + row.Name + '?')) {
        await row.delete();
    }
}

</script>

<template>
    <span v-for="(responsible, index) in responsibleData" class="card mt-1 d-flex flex-row flex-no-wrap p-1 me-2 align-items-center">
        <span class="me-auto d-inline-block text-truncate" :title="responsible.Name">
            {{ responsible.Name }}
        </span>
        <div class="d-flex align-items-center">
            <OActionList mReverseOrder>
                    <template #target="{ target }">
                            <a :ref="target" v-bind="$attrs">
                                    <i class="ms-auto me-1 font-4 dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"></i>
                            </a>
                    </template>
                    <OActionItem :text="$t('Send reminder e-mail')" @click="sendReminder(responsible)" />
                    <OActionItem class="text-danger" :text="$t('Remove person')" @click="removeResponsible(responsible)" />
                    <OActionItem v-if="!props.readOnly && dsSJA.current.Step_ID && !responsible.StepAction_ID" :text="$t('Create workflow action')" @click="createWorkflowAction(responsible, props.actionRow, dsSJA.current.Step_ID)" />
                    <button v-if="responsible.StepAction_ID" class="dropdown-item" disabled>
                        <i class="me-1 bi bi-circle-fill"
                            :class="{ 'text-danger': !responsible.Completed, 'text-success': responsible.Completed }"></i>
                    </button>
            </OActionList>
        </div>
    </span>
</template>