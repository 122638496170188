<script setup>
import { computed, watch, ref } from 'vue';
import { getDataObjectById } from 'o365-dataobject';
import ResponsiblesCardMobile from 'sja.vue.libraries.mob.ResponsiblesCardMobile.vue'
import { OPersonsLookup } from 'o365-system-lookups';
import { procAddResponsible } from 'sja.vue.libraries.modules.utilities.js'
import { getOrCreateProcedure } from 'o365-modules';

const props = defineProps({
    hazardRow: Object,
    sjaID: Number,
    itemID: Number,
    readOnly: Boolean,
    participants: Boolean
});

const procSendActionNotification = getOrCreateProcedure({ id: "procSendActionReminder", procedureName: "astp_SJA_SendTaskEmail" });
const procNotifyResponsible = getOrCreateProcedure({ id: "procNotifyResponsible", procedureName: "astp_SJA_NotifyResponible" });
let selPerson = ref(null);
let selRow = ref(null);
let selSJA = ref(null);

let dsActions = getDataObjectById(`dsTasksHazardsActions_${props.sjaID}`)
let dsParticipants = null;

if (props.participants) {
    dsParticipants = getDataObjectById(`dsParticipants${props.itemID ? "_" + props.sjaID : ""}`);
}

const actionData = computed(() => {
    return dsActions.data.filter(item => item.SJATasksHazard_ID == props.hazardRow.ID);
});

watch(selPerson, async (newPerson, prevPerson) => {
    let hasError = false;
    if (newPerson !== null && selSJA.value == props.sjaID) {
        let dataObject = getDataObjectById(`dsTasksHazardsActionsResp_${props.sjaID}`)
        try {
            let responsibleID = await procAddResponsible.execute({ SJATasksHazardsAction_ID: selRow.value.ID, Responsible_ID: selPerson.value, });
            // procSendActionNotification.execute({ SJA_ID: props.sjaID, Person_ID: selPerson.value, SJATasksHazardsAction_ID: selRow.value.ID, TypeCode: 'SJA_ACTION_NEW' });
            procNotifyResponsible.execute({ SJA_ID: props.sjaID, TargetPerson_ID: selPerson.value, Responsible_ID: responsibleID.Table[0].InsertedID, SJATasksHazardsAction_ID: selRow.value.ID });
        } catch (error) {
            hasError = true;
        } finally {
            if (hasError === false) {
                dataObject.load();
                if (props.participants) {
                    dsParticipants.load();
                }
            }
            selPerson.value = null;
            selRow.value = null;
        }
    }
})

// const procCreateActionRow = getOrCreateProcedure({ id: "procCreateActionRow", procedureName: "astp_SJA_CreateActionRow" });

// async function newRow(row) {
    // let dsTasksHazardsActions = null;
    // let dsTasksHazardsActionsResp = null;
    // try {
        // await procCreateActionRow.execute({ ID: row.ID });
        // dsTasksHazardsActions = getDataObjectById(`dsTasksHazardsActions_${row.SJA_ID}`);
        // dsTasksHazardsActionsResp = getDataObjectById(`dsTasksHazardsActionsResp_${row.SJA_ID}`);
        // await dsTasksHazardsActions.load();
        // await dsTasksHazardsActionsResp.load();
        // const scrollElement = document.querySelector('.flex-grow-1.scrollable.d-flex.flex-column');
        // scrollElement.scrollTo({
            // top: scrollElement.scrollHeight,
            // behavior: 'smooth'
        // });
    // } catch (error) {
        // console.error("Error loading data:", error);
    // }
// }

// onMounted(() => {
    // if (!props.readOnly) {
        // checkActions();
    // }
// })

// function checkActions() {
    // let allActionTabs = document.querySelectorAll(`.action-tab-${props.hazardRow.ID}`);
    // if (allActionTabs.length > 0) {
        // allActionTabs.forEach((tab) => {
            // tab.innerHTML = $t('New Actions') + `<i type="button" class="new-action-button-${props.hazardRow.ID} ms-2 bi bi-plus-circle"></i>`;
            // tab.classList.add('fs-6')
        // })
        // const icon = document.querySelector(`.new-action-button-${props.hazardRow.ID}`);
        // icon.addEventListener("click", function () {
            // newRow(props.hazardRow);
        // });
        // icon.addEventListener("mouseover", function () {
            // const title = $t('Add a new action.');
            // icon.setAttribute('title', title);
        // });
    // }
// }

async function deleteAction(row) {
    if (confirm($t("Delete Action") + '\n' + $t('Are you sure you wish to delete') + ' ' + row.Name + '?')) {
        row.delete();
    }
}
</script>

<template>
        <div v-for="(action, index) in actionData" class="card m-2 p-2 d-flex flex-column" :class="index != actionData.length - 1 ? 'border-bottom' : ''">
            <div class="mb-1 px-1">
                <span>{{ $t('Action Name') }}:</span>
                <OContentEditable class="w-90 h-100 p-1 text-break form-control"
                    :class="{ 'inner-table-editable': !props.readOnly }" :isEditable="!props.readOnly"
                    v-model="action.Name" @blur="dsActions.save()" />
            </div>

            <div class="mb-1 px-1">
                <span>{{ $t('Comment') }}:</span>
                <OContentEditable class="w-90 h-100 p-1 text-break form-control"
                    :class="{ 'inner-table-editable': !props.readOnly }" :isEditable="!props.readOnly"
                    v-model="action.Comment" @blur="dsActions.save()" />
            </div>

            <div class="mb-3 px-1">
                <span>{{ $t('Responsible') }}:</span>
                <ResponsiblesCardMobile :actionRow="action" :sjaID="props.sjaID" :readOnly="props.readOnly" />
                <div v-if="!props.readOnly" class="text-center mt-1">
                    <OPersonsLookup class="form-select"
                        :bind="sel => { selPerson = sel.ID; selRow = action; selSJA = props.sjaID }">
                        <template #person>
                            <i :title="$t('Add a person as responsible for the action.')" type="button"
                                class="px-3 fs-2 text-black bi bi-person-plus-fill">
                            </i>
                        </template>
                    </OPersonsLookup>
                </div>
            </div>

            <div class="text-center col-auto mt-auto">
                <button confirm v-if="!props.readOnly" class="btn btn-sm btn-danger py-0 px-1" :title="$t('Delete action.')" style="-bs-btn-line-height: 1;" @click="deleteAction(action)">
                    {{ $t('Delete action') }}
                </button>
            </div>
        </div>
</template>