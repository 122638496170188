<template>
    <table style="table-layout:fixed; width:100%; display: block; font-size: 90%;">
        <tr style="border-bottom: 1px solid #DFE2E6; background: #A8C0D8; padding-top: 10px; height: 34px;">
            <template v-for="column in gridJson.fields">
                <th style="border-right: 1px solid #6B94BD; padding-left: 0.4375em; font-weight: 600;" :style="{'width': column.width+'px' }">{{ column.title ? column.title : column.name }}</th>
            </template>
        </tr>
        <template v-for="row in dataArray">
            <tr style="border-bottom: 1px solid #DFE2E6;">
                <template v-for="col in gridJson.fields">
                    <td style="padding: 0.32rem;" :style="{'width': col.width+'px'}">{{ getValue(row[col.name]) }}</td>
                </template>
            </tr>
        </template>
    </table>
</template>

<script setup lang="ts">
    import { ref } from 'vue';
    import { utils } from 'o365-utils';
    import { getOrCreateProcedure } from 'o365-modules';
    import { getOrCreateDataObject } from 'o365-dataobject';

    const props = defineProps({
        layoutId: {
            type: Number,
            default: null,
            required: true
        }
    });

    const dsLayout = getOrCreateDataObject({
        id: 'dsLayout'+crypto.randomUUID(),
        viewName: 'sviw_O365_TableLayouts',
        maxRecords: 1,
        whereClause: "ID = " + props.layoutId,
        selectFirstRowOnLoad: true,
        fields: [
            { name: "ID", type: "number" },
            { name: "Layout", type: "string" },
            { name: "StoredProcedure", type: "string" },
            { name: "ParametersForPreviewData", type: "string" },
        ]
    });
    
    const gridJson = ref({fields:[]});
    const dataArray = ref([]);
    var loadDataProc:any = null;

    dsLayout.load().then(async ()=>{
        if(dsLayout.current){
            gridJson.value = JSON.parse(dsLayout.current.Layout??"{}");
            loadDataProc = getOrCreateProcedure({ id: dsLayout.current.StoredProcedure, procedureName: dsLayout.current.StoredProcedure });
            if (dsLayout.current.ParametersForPreviewData != null) {
                const parameters = dsLayout.current.ParametersForPreviewData.split("&");
                const parametersObj = getParameterObj(parameters);
                loadDataProc.execute(parametersObj).then((response)=>{
                    dataArray.value = response.Table;
                });
            } else {
                loadDataProc.execute().then((response)=>{
                    dataArray.value = response.Table;
                });;
            }
        }
    });
    function getParameterObj(parameters: Array<string>) {
        const obj: any = {};
        parameters.forEach(param => {
            const paramKeyValue = param.split("=");
            obj[paramKeyValue[0]] = paramKeyValue[1];
        })
        return obj;
    }

    function getValue(value){
        if(parseDate(value)){
            return utils.formatDate(value, "Short Date");
        }else{
            return value;
        }
    }

    const parseDate = (pDate) => {
        if (!pDate) return null;
        if(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,7}))?Z?$/.test(pDate)){
            return new Date(pDate);
        }
        switch (pDate) {
            case "":
                return null;
            case /^\d{4}-\d{2}-\d{2}[ T]\d{2}:\d{2}/.test(pDate) && pDate:
                return new Date(pDate);
            case /^\d{4}-\d{2}-\d{2}[ T]\d{2}:\d{2}:\d{2}/.test(pDate) && pDate:
                return new Date(pDate);
            default:
                if(tryCheckDate(pDate)){
                    return new Date(pDate);
                }
                if(tryCheckDateLeadingZeros(pDate)){
                    return new Date(addLeadingZeros(pDate));
                }
                return null;
        }
    };

    const tryCheckDateLeadingZeros = (pDate) => {
        let vDate = new Date(addLeadingZeros(pDate));
        if (vDate && vDate.toDateString() !== 'Invalid Date') {
            return true;
        }
        return false;
    };
    const tryCheckDate = (pDate) => {
        if (!/\d{4}-\d{2}-\d{2}/.test(pDate)) return false;
        let d = new Date(pDate);
        if (d.toISOString() === pDate + "T00:00:00.000Z") return true;
        let vDate = new Date(addLeadingZeros(pDate));
        if (vDate && vDate.toDateString() !== 'Invalid Date') {
            return true;
        }
        return false;
    };

    const addLeadingZeros = (pDate) => {
        if(!getDateSeparator(pDate)){
            return "";
        }
        if (pDate) {
            var vSeparator = getDateSeparator(pDate.split(" ")[0]);
            var vSplitted = pDate.split(vSeparator),
                vParts:any = [];
            vSplitted.forEach(function (item) {
                if (String(item).length === 1) {
                    vParts.push("0" + item);
                } else {
                    vParts.push(item);
                }
            });
            return vParts.join(vSeparator);
        }
        return pDate;
    };

    const getDateSeparator = (pDate) => {
        try{
            if (pDate.indexOf(".") > -1) return ".";
            if (pDate.indexOf("/") > -1) return "/";
            if (pDate.indexOf("-") > -1) return "-";
        }catch{
            return false;
        }
    };
</script>